import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "react-modal";
import UserDataService from "../../../services/userData";
import TripDataService from "../../../services/TripData";
import KeyInformationDesktop from "./KeyInformationDesktop";
import StaticDataService from "../../../services/staticData";
import Lottie from 'lottie-react';
import animationData from '../../../animation-1.json';
import Select from 'react-select';

const PassangerDetail = ({action}) => {
  Modal.setAppElement('#root');

  // active tabs open and close
  const [activeTab, setActiveTab] = useState(1);
  const [userPData, setUserPData] = useState([]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [BtnDissabled, setBtnDissabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30); 
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [showResendText, setShowResendText] = useState(true);
  //const [action, setAction] = useState(action);
  const [loading, setLoading] = useState(false);
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cleanedNumber, setCleanedNumber] = useState('');
  const [pageFormData, setFormData] = useState({
    first_name: userPData?.name || "",
    last_name: userPData?.last_name || "",
    user_email: userPData?.email || "",
    user_mobilenumber: cleanedNumber || "",
    user_countrycode: "+91",
    agree: false,
  });

  useEffect(() => {
    setFormData({
      first_name: userPData?.name || "",
      last_name: userPData?.last_name || "",
      user_email: userPData?.email || "",
      user_mobilenumber: cleanedNumber || "",
      // user_mobilenumber: cleanedNumber || userPData?.mobile || "", 
      user_countrycode: "+91",
      agree: pageFormData.agree || false,
    });
  }, [userPData,cleanedNumber],);
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
    
  };

  const handleRemoveCountryCode = (inputNumber) => {
    //inputNumber = '7741919219';
    const regex = /^\+(\d{1,4})[\s-]?(\d{10,15})$/;

    const match = inputNumber.match(regex);

    const numberWithoutCode = match ? match[2] : inputNumber;

    setCleanedNumber(numberWithoutCode.trim());
  };
  

  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    setUserPData(storedUserData);
    if (storedUserData) {
      handleRemoveCountryCode(storedUserData.mobile);
      setActiveTab(2); 
      setIsUserLoggedIn(true); 
    }
  }, []);

    useEffect(() => {
      // Start the timer when isTimerRunning is true
      let timer;
      if (isTimerRunning) {
        timer = setInterval(() => {
          setResendTimer(prevTimer => prevTimer - 1);
        }, 1000);
      }
  
      // Clear the timer when it reaches 0
      if (resendTimer === 0) {
        clearInterval(timer);
        setIsTimerRunning(false);
        setShowResendText(true);
        setResendTimer(30); 
      }
  
      // Clean up function
      return () => clearInterval(timer);
    }, [isTimerRunning, resendTimer]);


    useEffect(() => {
      if (action) {
        // Perform the action here when the 'Next' button is clicked
    
        if (!userPData) {
          if (pageFormData.first_name && pageFormData.last_name && pageFormData.user_email && pageFormData.user_mobilenumber) {
            var rawData = JSON.stringify({
              name: pageFormData.first_name,
              last_name: pageFormData.last_name,
              mobile: pageFormData.user_mobilenumber,
              email: pageFormData.user_email,
              countryCode: pageFormData.user_countrycode,
              role: 'user',
              otp: "219632",
            });
            //return false;
            UserDataService.userRegister(rawData)
              .then((response) => {
                if (response.success) {
                  var userData = response.user_profile;
                  localStorage.setItem("userData", JSON.stringify(userData));
                  setUserPData(userData);
                  handleTabClick(2);
                  bookNowBtnClick();
                } else {
                }
              })
              .catch((err) => {});
          }
          else {
            setIsBrowserAlerts("Please sign in or enter your details"); 
          }
        }
        else{
          //setIsBrowserAlerts("Please sign in or enter your details userpdata failes"); 
          bookNowBtnClick();
        }
      }
      else{
        action = false;
      }
    }, [action]);


  const handleTabClick = (tabNumber) => {
    if (userPData) {
      return; 
    }
    setActiveTab(tabNumber);
  };


  //Country Code data start
  // const toggleOptions = () => {
  //   setIsOpen(!isOpen);
  // };

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };

  const [selectedOption, setSelectedOption] = useState(null);
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedOptionGuest, setSelectedOptionGuest] = useState(null);
  const [countryCodesGuest, setCountryCodesGuest] = useState([]);

  useEffect(() => {
    fetch('assets/CountryCodes.json')
      .then(response => response.json())
      .then(data => {
        // Convert the JSON data into a format compatible with react-select
        const options = data.map(country => ({
          value: country.value,
          label: country.label 
        }));
        setCountryCodes(options);
        setCountryCodesGuest(options);
        //set default
        const defaultOption = options.find(option => option.value === '+91');
        setSelectedOption(defaultOption);
        const defaultOptionGuest = options.find(option => option.value === '+91');
        setSelectedOptionGuest(defaultOptionGuest);
      })
      .catch(error => {
        console.error('Error fetching country codes:', error);
      });
  }, []);

  // Handle selection
  const handleOptionClick = (selectedOption) => {
    setSelectedOption(selectedOption); 
  };

  const handleOptionClickGuest = (selectedOptionGuest) => {
    setSelectedOptionGuest(selectedOptionGuest); 
  };


  // input tick mobile number
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputChange = (e) => {
    const input = e.target.value.replace(/[^0-9]/g, "");
    const fixinputNumbers = input.slice(0, 10);
    setPhoneNumber(fixinputNumbers);

    // Display the tick mark if the input length is 10
    const tickMark = document.getElementById("tickMark");
    if (input.length === 10) {
      tickMark.style.display = "inline";
    } else {
      tickMark.style.display = "none";
    }
  };

   // For Browser ALerts
   const [isBrowserAlerts, setIsBrowserAlerts] = useState();
   const [allowBooking, setallowBooking] = useState(false);
   const closeBrowserModal = () => {
     setIsBrowserAlerts('');
   };

 //  for alert wrong numaber case
 const [isWrongNumberAlerts, setIsWrongNumberAlerts] = useState('');
 const closeWrongNumberModal = () => {
  setIsWrongNumberAlerts('');
  setLoading(false); // Stop loading if error occurs
  setActiveTab(2);
 };

 const validateEmail = (email) => {
  //const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailRegex.test(email);
};

 
  // otp section
  const [showOTPSection, setShowOTPSection] = useState(false);

  const bookNowBtnClick = async () => {
    setLoading(true); 
    var storedUserData = null;
    if ((!pageFormData.first_name || !pageFormData.user_email || !pageFormData.last_name || !pageFormData.user_mobilenumber)) {
        setLoading(false); 
        setIsBrowserAlerts("Please enter all details");
        return false;
    }
    //console.log(pageFormData, userPData);
    if (pageFormData.agree != true) {
      setLoading(false); 
      setIsBrowserAlerts("Please Select Terms And Conditions");
      return false;
    }
    if (!userPData) {
      var rawData = JSON.stringify({
        name: pageFormData.first_name,
        last_name: pageFormData.last_name,
        mobile: pageFormData.user_mobilenumber,
        email: pageFormData.user_email,
        countryCode: pageFormData.user_countrycode,
        role: 'user',
        otp: "219632",
      });
      UserDataService.userRegister(rawData)
        .then((response) => {
          //storedUserData = response.user_profile;
          if (response.success === true) {
            //console.log("response in status check",response);
            var userData = response.user_profile;
            localStorage.setItem("userData", JSON.stringify(userData));
            //userData.agree = pageFormData.agree;
            storedUserData = userData;
            setallowBooking(true);
            setUserPData(userData);
            handleRemoveCountryCode(userData.mobile);
            pageFormData.user_mobilenumber = cleanedNumber;
            processBooking();
            //return false;
            //handleTabClick(2);
            //console.log("After form signup response: ",response.user_profile);
          } else {
            console.log("in signup else: ",response);
          }
        })
        .catch((err) => {});

        //console.log("After form signup: ",storedUserData, userPData);
    } else {
      var rawData = JSON.stringify({
        name: pageFormData.first_name,
        last_name: pageFormData.last_name,
        mobile: pageFormData.user_mobilenumber,
        email: pageFormData.user_email,
        countryCode: pageFormData.user_countrycode,
        role: 'user',
        otp: "219632",
      });
      UserDataService.userRegister(rawData)
        .then((response) => {
          
          if (response.success) {
            var userData = response.user_profile;
            localStorage.setItem("userData", JSON.stringify(userData));
            //userData.agree = pageFormData.agree;
            storedUserData = userData;
            setallowBooking(true);
            setUserPData(userData);
            console.log("Vinod Signup checking : ", userData);
            processBooking();
            //handleTabClick(2);
          } else {
            console.log("Fail ",  response);
            setLoading(false); 
            setallowBooking(false);
            setIsBrowserAlerts("Please enter valid details");
            return false;
          }
        })
        .catch((err) => {});
      //setIsBrowserAlerts("Booknow Button clicked");
    }

    return false;
  };

  const processBooking = async () => {

/* console.log("pageFormData", pageFormData);
    return false; */
    if (pageFormData.agree != true) {
      setLoading(false); 
      setIsBrowserAlerts("Please Select Terms And Conditions");
      return false;
    }
    const storeTripdData = JSON.parse(await localStorage.getItem("formData"));
    const storedUserData = JSON.parse(await localStorage.getItem("userData"));

    //return false
    var step1Object = {};
    var step2Object = {};
    console.log("passengersData : ", storedUserData);
      setBtnDissabled(true);
      if (storeTripdData.triptype === "onward") {
        if (
          storeTripdData &&
          storeTripdData.onwardJourney &&
          storeTripdData.onwardJourney.passengersData
        ) {
          const { onwardJourney } = storeTripdData;
          const { passengersData, SelTripData } = onwardJourney;
          const { selectedVehicles, petinfo, available, vehicleRouteDetails } =
            SelTripData;

          step1Object = {
            cruise_id: SelTripData.cruise_id,
            sailing_date: SelTripData.sailing_date,
            sailing_time: SelTripData.sailing_time,
            sailing_type: "1_way",
            sailing_way: "onward",
            session_id: SelTripData.session_id,
          };

          step1Object["total_room"] = passengersData.Adultcount;
          let guest = [];
          for (let i = 0; i < passengersData.Adultcount; i++) {
            guest.push({
              adult: 1,
              child: 0,
              infant: 0,
              category_id: available.category_id,
            });
          }
          for (let i = 0; i < passengersData.Childcount; i++) {
            guest.push({
              adult: 0,
              child: 0,
              infant: 1,
              category_id: available.category_id,
            });
          }
          step1Object["guest_count_details"] = guest;

          TripDataService.fetchCruiseRoomAvailability(JSON.stringify(step1Object))
            .then((step1resp) => {
              
              if (step1resp.status == "success") {
                step2Object = step1Object;
                step2Object["sailing_type"] = "1_way";
                step2Object["sailing_way"] = "onward";
                step2Object["session_id"] = step1resp.session_id;
                step2Object["total_room"] = passengersData.Adultcount;
                step2Object["guest_details"] = [];
                
                let d = new Date(step2Object.sailing_date);
                let randNumber =
                  Math.floor(Math.random() * (99999 - 100 + 1)) + 1000;
                //Add Passenger Data
                
                for (let i = 0; i < passengersData.Adultcount; i++) {
                  const data = {};
                  if (i == 0) {
                    data["first_name"] = storedUserData.name;
                    data["last_name"] = storedUserData.last_name;
                    data["country_code"] = storedUserData.country_code;
                    data["phone"] = storedUserData.mobile;
                    data["email"] = storedUserData.email;
                    const tmp_adult_year = d.getFullYear() - 22;
                    data["dob"] = "01/01/" + tmp_adult_year;
                  } else {
                    data["first_name"] = "tmp_first_name_" + i;
                    data["email"] =
                      "tmp_email_" + randNumber + "@tmp" + i + ".web";
                    data["last_name"] = "tmp_last_name_web_" + i;

                    data["phone"] =
                      Math.floor(Math.random() * (9999999999 - 1111111111 + 1)) +
                      1;
                    const tmp_adult_year = d.getFullYear() - 20;
                    data["dob"] = "01/01/" + tmp_adult_year;
                  }
                  data["room_no"] = step1resp.room_details[i].room_name;
                  data["room_id"] = step1resp.room_details[i].room_id;
                  step2Object["guest_details"].push(data);
                }
                
                //Add Infant Data
                for (let j = 0; j < passengersData.Childcount; j++) {
                  const data1 = {};
                  data1["first_name"] = "tmp_infant_fname_" + j;
                  data1["email"] =
                    "tmp_infant_email_" + randNumber + "@tmp" + j + ".web";
                  data1["last_name"] = "tmp_infant_lname_" + j;

                  data1["phone"] =
                    Math.floor(Math.random() * (9999999999 - 1111111111 + 1)) + 1;
                  let tmp_infant_year = d.getFullYear() - 1;
                  data1["dob"] = "01/01/" + tmp_infant_year;
                  data1["room_no"] = step1resp.room_details[j].room_name;
                  data1["room_id"] = step1resp.room_details[j].room_id;
                  step2Object["guest_details"].push(data1);
                }

                //Add Vehicle Data
                step2Object["onshore_excursion"] = [];
                if (selectedVehicles) {
                  selectedVehicles.forEach((item) => {
                    const vehicleEntry = {
                      id: item.excursion_id,
                      quantity: item.quantity,
                      //Pending add
                      sailing_id: vehicleRouteDetails.onward.onward_sailing_id,
                      route_id: vehicleRouteDetails.onward.onward_route_id,
                    };
                    step2Object["onshore_excursion"].push(vehicleEntry);
                  });
                }
                
                if (petinfo && passengersData.Petcount > 0) {
                  step2Object["onshore_excursion"].push({
                    id: petinfo.excursion_id,
                    quantity: passengersData.Petcount,
                    //Pending add
                    sailing_id: vehicleRouteDetails.onward.onward_sailing_id,
                    route_id: vehicleRouteDetails.onward.onward_route_id,
                  });
                }
                //console.log("step2Object", step2Object);
                TripDataService.fetchCruiseBookingGuestPreview(
                  JSON.stringify(step2Object)
                )
                  .then((step2resp) => {
                    //console.log(step2resp);
                    if (step2resp.status == "success") {
                      step2resp["onshore_excursion"] =
                        step2Object["onshore_excursion"];
                      step2resp["booking_source"] = "website";

                      delete step2resp["onshore_excursion_details"];

                      TripDataService.fetchCruiseBookingConfirm(
                        JSON.stringify(step2resp)
                      )
                        .then((step3resp) => {
                          //console.log(step3resp);
                          if (step3resp.status == "success") {
                            // Redirect to Payment Gateway or itegrate Juspay SDK
                            //console.log(step3resp);
                            //setIsBrowserAlerts("Redirect to Payment Gateway");
                            //window.location = step3resp["juspay-payment-link"];
                            
                            localStorage.removeItem("formData");
                            window.location = step3resp["payment_link"];
                          } else {
                            setIsBrowserAlerts(step3resp.message);
                            setBtnDissabled(false);
                            setLoading(false); // Stop loading if error occurs
                            return false;
                          }
                        })
                        .catch((err) => {});
                    } else {
                      setIsBrowserAlerts(step2resp.message);
                      setBtnDissabled(false);
                      setLoading(false); // Stop loading if error occurs
                      return false;
                    }
                  })
                  .catch((err) => {});
              } else {
                setIsBrowserAlerts(step1resp.message);
                setBtnDissabled(false);
                setLoading(false); // Stop loading if error occurs
                return false;
              }
            })
            .catch((err) => {});
        }
      } else {
        // Return Journey
        if (storeTripdData) {
          const { onwardJourney } = storeTripdData;
          const { passengersData, SelTripData } = onwardJourney;
          const { selectedVehicles, petinfo, available, vehicleRouteDetails } =
            SelTripData;

          const { returnJourney } = storeTripdData;
          const {
            passengersData: returnPassengersData,
            SelTripData: returnSelTripData,
          } = returnJourney;
          const {
            selectedVehicles: returnSelectedVehicles,
            petinfo: returnPetinfo,
            available: returnAvailable,
            vehicleRouteDetails: returnVehicleRouteDetails,
          } = returnSelTripData;

          step1Object = {
            cruise_id: SelTripData.cruise_id,
            sailing_date: SelTripData.sailing_date,
            sailing_time: SelTripData.sailing_time,
            sailing_type: "1_way",
            sailing_way: "onward",
            session_id: SelTripData.session_id,
          };

          step1Object["total_room"] = passengersData.Adultcount;
          let guest = [];
          for (let i = 0; i < passengersData.Adultcount; i++) {
            guest.push({
              adult: 1,
              child: 0,
              infant: 0,
              category_id: available.category_id,
            });
          }
          for (let i = 0; i < passengersData.Childcount; i++) {
            guest.push({
              adult: 0,
              child: 0,
              infant: 1,
              category_id: available.category_id,
            });
          }
          step1Object["guest_count_details"] = guest;

          TripDataService.fetchCruiseRoomAvailability(JSON.stringify(step1Object))
            .then((step1resp) => {
              //console.log(step1resp);
              if (step1resp.status == "success") {
                const step1ReturnObject = {
                  cruise_id: returnSelTripData.cruise_id,
                  sailing_date: returnSelTripData.sailing_date,
                  sailing_time: returnSelTripData.sailing_time,
                  sailing_type: "custom",
                  sailing_way: "onward",
                  session_id: SelTripData.session_id,
                  total_room: returnPassengersData.Adultcount,
                };

                //step1ReturnObject['total_room'] = returnPassengersData.Adultcount;
                let guest = [];
                for (let i = 0; i < returnPassengersData.Adultcount; i++) {
                  guest.push({
                    adult: 1,
                    child: 0,
                    infant: 0,
                    category_id: returnAvailable.category_id,
                  });
                }
                for (let i = 0; i < returnPassengersData.Childcount; i++) {
                  guest.push({
                    adult: 0,
                    child: 0,
                    infant: 1,
                    category_id: returnAvailable.category_id,
                  });
                }
                step1ReturnObject["guest_count_details"] = guest;

                TripDataService.fetchCruiseRoomAvailability(
                  JSON.stringify(step1ReturnObject)
                )
                  .then((step1Returnresp) => {
                    //console.log(step1Returnresp);
                    if (step1Returnresp.status == "success") {
                      const customBookingObject = {
                        onward_cruise_id: SelTripData.cruise_id,
                        return_cruise_id: returnSelTripData.cruise_id,
                        onward_sailing_date: SelTripData.sailing_date,
                        return_sailing_date: returnSelTripData.sailing_date,
                        onward_sailing_time: SelTripData.sailing_time,
                        return_sailing_time: returnSelTripData.sailing_time,
                        total_room: returnPassengersData.Adultcount,
                        sailing_type: "custom",
                        channel_phone: "",
                        session_id: SelTripData.session_id,
                        guest_details: [],
                        onshore_excursion: [],
                        onboard_excursion: [],
                        sailing_way: "onward",
                        encrypted_uid: "",
                        source_code: "",
                        is_add_on: "false",
                      };
                      //console.log(selectedVehicles, vehicleRouteDetails);
                      const onshorevehicleObjects = [];
                      if (selectedVehicles) {
                        selectedVehicles.forEach((item) => {
                          var vehicleEntry = {
                            id: item.excursion_id,
                            quantity: item.quantity,
                            sailing_id:
                              vehicleRouteDetails.onward.onward_sailing_id,
                            route_id: vehicleRouteDetails.onward.onward_route_id,
                          };
                          onshorevehicleObjects.push(vehicleEntry);
                          // var vehicleEntry = {"id":item.excursion_id,"quantity":item.quantity,"sailing_id":this.vehicleRouteDetails.return_sailing_id,"route_id":this.vehicleRouteDetails.return_route_id};
                          // vehicleObjects.push(vehicleEntry);
                        });
                      }
                      //console.log(customBookingObject, onshorevehicleObjects);
                      if (petinfo && passengersData.Petcount > 0) {
                        onshorevehicleObjects.push({
                          id: petinfo.excursion_id,
                          quantity: passengersData.Petcount,
                          sailing_id:
                            vehicleRouteDetails.onward.onward_sailing_id,
                          route_id: vehicleRouteDetails.onward.onward_route_id,
                        });
                      }

                      const onbordvehicleObjects = [];
                      if (returnSelectedVehicles) {
                        returnSelectedVehicles.forEach((item) => {
                          var vehicleEntry = {
                            id: item.excursion_id,
                            quantity: item.quantity,
                            sailing_id:
                              returnVehicleRouteDetails.return.return_sailing_id,
                            route_id:
                              returnVehicleRouteDetails.return.return_route_id,
                          };
                          onshorevehicleObjects.push(vehicleEntry);
                        });
                      }
                      if (petinfo && passengersData.Petcount > 0) {
                        onshorevehicleObjects.push({
                          id: petinfo.excursion_id,
                          quantity: passengersData.Petcount,
                          sailing_id:
                            returnVehicleRouteDetails.return.return_sailing_id,
                          route_id:
                            returnVehicleRouteDetails.return.return_route_id,
                        });
                      }
                      //console.log(customBookingObject, onshorevehicleObjects, onbordvehicleObjects);
                      customBookingObject.onshore_excursion = onshorevehicleObjects;
                      customBookingObject.onboard_excursion = onbordvehicleObjects;

                      let d = new Date(customBookingObject.onward_sailing_date);
                      let randNumber =
                        Math.floor(Math.random() * (99999 - 100 + 1)) + 1000;
                      const guesrData = [];

                      //Add Return Passenger Data
                      for (let i = 0; i < returnPassengersData.Adultcount; i++) {
                        const tempData = {};
                        if (i == 0) {
                          tempData["first_name"] = storedUserData.name;
                          tempData["last_name"] = storedUserData.last_name;
                          tempData["country_code"] = storedUserData.country_code;
                          tempData["phone"] = storedUserData.mobile;
                          tempData["email"] = storedUserData.email;
                          const tmp_adult_year = d.getFullYear() - 22;
                          tempData["dob"] = "01/01/" + tmp_adult_year;
                        } else {
                          tempData["first_name"] = "tmp_first_name_" + i;
                          tempData["email"] =
                            "tmp_email_" + randNumber + "@tmp" + i + ".web";
                          tempData["last_name"] = "tmp_last_name_web_" + i;

                          tempData["phone"] =
                            Math.floor(
                              Math.random() * (9999999999 - 1111111111 + 1)
                            ) + 1;
                          const tmp_adult_year = d.getFullYear() - 20;
                          tempData["dob"] = "01/01/" + tmp_adult_year;
                        }
                        tempData["onward_room_no"] =
                          step1resp.room_details[i].room_name;
                        tempData["return_room_no"] =
                          step1Returnresp.room_details[i].room_name;
                        tempData["onward_room_id"] =
                          step1resp.room_details[i].room_id;
                        tempData["return_room_id"] =
                          step1Returnresp.room_details[i].room_id;
                        guesrData.push(tempData);
                      }
                      //Add Return Infant Data
                      for (let j = 0; j < returnPassengersData.Childcount; j++) {
                        const tempData1 = {};
                        tempData1["first_name"] = "tmp_infant_fname_" + j;
                        tempData1["email"] =
                          "tmp_infant_email_" + randNumber + "@tmp" + j + ".web";
                        tempData1["last_name"] = "tmp_infant_lname_" + j;

                        tempData1["phone"] =
                          Math.floor(
                            Math.random() * (9999999999 - 1111111111 + 1)
                          ) + 1;
                        let tmp_infant_year = d.getFullYear() - 1;
                        tempData1["dob"] = "01/01/" + tmp_infant_year;

                        tempData1["onward_room_no"] =
                          step1resp.room_details[j].room_name;
                        tempData1["return_room_no"] =
                          step1Returnresp.room_details[j].room_name;
                        tempData1["onward_room_id"] =
                          step1resp.room_details[j].room_id;
                        tempData1["return_room_id"] =
                          step1Returnresp.room_details[j].room_id;
                        guesrData.push(tempData1);
                      }

                      guesrData[0].first_name = storedUserData.name;
                      guesrData[0].last_name = storedUserData.last_name;
                      guesrData[0].phone = storedUserData.mobile;
                      guesrData[0].email = storedUserData.email;

                      customBookingObject.guest_details = guesrData;
                      //console.log(customBookingObject);

                      TripDataService.fetchCruiseCustomBookingPreview(
                        JSON.stringify(customBookingObject)
                      )
                        .then((step3resp) => {
                          //console.log(step3resp);
                          if (step3resp.status == "success") {
                            const conformData = step3resp;

                            delete conformData.onboard_excursion_details;
                            delete conformData.onshore_excursion_details;
                            conformData["onshore_excursion"] =
                              customBookingObject.onshore_excursion;
                            conformData["onboard_excursion"] =
                              customBookingObject.onboard_excursion;
                            conformData["booking_source"] = "website";

                            TripDataService.fetchCruiseCustomBookingConfirm(
                              JSON.stringify(step3resp)
                            )
                              .then((step4resp) => {
                                //console.log(step4resp);
                                if (step4resp.status == "success") {
                                  // Redirect to Payment Gateway or itegrate Juspay SDK
                                  //window.location = step4resp["juspay-payment-link"];
                                  
                                  localStorage.removeItem("formData");
                                  window.location = step4resp["payment_link"];
                                } else {
                                  setIsBrowserAlerts(step4resp.message);
                                  setBtnDissabled(false);
                                  setLoading(false); // Stop loading if error occurs
                                  return false;
                                }
                              })
                              .catch((err) => {});
                          } else {
                            setIsBrowserAlerts(step3resp.message);
                            setBtnDissabled(false);
                            setLoading(false); // Stop loading if error occurs
                            return false;
                          }
                        })
                        .catch((err) => {});
                    } else {
                      setIsBrowserAlerts(step1Returnresp.message);
                      setBtnDissabled(false);
                      setLoading(false); // Stop loading if error occurs
                      return false;
                    }
                  })
                  .catch((err) => {});
              } else {
                setIsBrowserAlerts(step1resp.message);
                setBtnDissabled(false);
                setLoading(false); // Stop loading if error occurs
                return false;
              }

              //1st then
            })
            .catch((err) => {});
        }
      }
  }

  const handleGetOTPClick = () => {
    if (phoneNumber.length === 10) {
      var rawData = JSON.stringify({ mobile: phoneNumber, step: "login" });
      UserDataService.requestOtp(rawData)
        .then((response) => {
          //console.log(response);
          if (response.success) {
            setShowOTPSection(true);
            // Start the timer when OTP is sent
            setIsTimerRunning(true);
            setShowResendText(false);
          } else {
            // setIsBrowserAlerts("It seems like you don't have an M2M account yet. Create your account now!");
            setIsWrongNumberAlerts(response.message);
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //
    } else {
      setIsBrowserAlerts("Please enter mobile number");
    }
  };

  const handleOTPSubmit = () => {
    const stringOtp = enteredOTP.map((value) => value).join("");

    //return false;
    if (enteredOTP.length === 6) {
      var rawData = JSON.stringify({ mobile: phoneNumber, otp: stringOtp });
      UserDataService.userLogin(rawData)
        .then((response) => {
          if (response.success) {
            var userData = response.user_profile;
            localStorage.setItem("userData", JSON.stringify(userData));
            setUserPData(userData);
            // handleTabClick(2);

            // Remove the country code and set the cleaned number
            handleRemoveCountryCode(userData.mobile);

            // Update the form data to reflect the mobile number immediately
            setFormData((prevData) => ({
              ...prevData,
              user_mobilenumber: userData.mobile, 
            }));

            handleTabClick(2);  
          } else {
            setIsBrowserAlerts(response.message);
            setEnteredOTP(["", "", "", "", "", ""]);
          }
        })
        .catch((err) => {});
         /* setIsBrowserAlerts("OTP is Correct");*/
    } else {
      setIsBrowserAlerts("Incorrect OTP. Please try again.");
      setEnteredOTP(["", "", "", "", "", ""]);
    }
  };

  
  const handleResendOTP = () => {
    // Resend OTP to the same mobile number
    handleGetOTPClick();
    // Reset timer
    setResendTimer(30);
    // Start timer again
    setIsTimerRunning(true);
  };

  // otp section
  const [enteredOTP, setEnteredOTP] = useState(["", "", "", "", "", ""]);
  const handleOTPChange = (index, value) => {
    // Allow only numeric input
    const numericValue = value.replace(/\D/g, "");

    const newOTP = [...enteredOTP];
    newOTP[index] = numericValue;

    // Move focus to the next input box if a digit is entered
    if (numericValue.length === 1 && index < 5) {
      const nextIndex = index + 1;
      const nextInput = document.getElementById(`otpInputBox${nextIndex}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (numericValue.length === 0 && index > 0) {
      // Move focus to the previous input box if the current box is empty
      const prevIndex = index - 1;
      const prevInput = document.getElementById(`otpInputBox${prevIndex}`);
      if (prevInput) {
        prevInput.focus();
      }
    }

    setEnteredOTP(newOTP);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log("Form data submitted:", pageFormData);
  };

  

  // onModel will be open(Popup)
  
  //FOR I AGREE TEXT CLCIK THEN CHECKBOX CLICK
  const handleParagraphClick = (event) => {
    // Toggle the checkbox manually
    const checkbox = document.getElementById('agree');
    if (checkbox) {
      checkbox.checked = !checkbox.checked; 
      handleChange({ target: { name: 'agree', type: 'checkbox', checked: checkbox.checked } }); // Call handleChange manually
    }
  };
  
  const openModal = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const currentUser = UserDataService.getCurrentUser();

  useEffect(() => {
    const handleScroll = () => {
      if (isModalOpen) {
        // Close the modal when scrolling
        closeModal();
      }
    };

    if (isModalOpen) {
      // Disable body scrolling
      document.body.style.overflow = "hidden";
      // Add scroll event listener to close the modal
      window.addEventListener("scroll", handleScroll);
    } else {
      // Enable body scrolling
      document.body.style.overflow = "auto";
      // Remove scroll event listener
      window.removeEventListener("scroll", handleScroll);
    }

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isModalOpen]);

  // for mobile popup will open

  const [isMobileOpenConfirm, setIsMobileOpenConfirm] = useState(false);
  const handleOpenPopupMobile = () => {
    setIsMobileOpenConfirm(true);
    document.body.classList.remove('noscroll');

  };
  const handleClosePopupMobile = () => {
    setIsMobileOpenConfirm(false);
    document.body.classList.remove('noscroll');

  };

  // key information comoentn
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Attach the event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [TemsData,setTermsData] = useState()
   useEffect(()=>{
    StaticDataService.StaticData()
    .then((response) => {
      setTermsData(response.data[3].description)
    })
    .catch((err) => {
      console.log(err,"error")
    });

  },[]) 


  //console.log("countryCodesGuest for my sites",pageFormData)

  return (
    <div className="Passnager_Detail" style={{ width: "100%" }}>
      {/* maindiv */}
      <div className="confirmation_summary_signin">
        {/* section 1 div 1 sigin */}
        {loading ? (
           <div className="lottie-for-payment-getway"> 
              <Lottie animationData={animationData} 
                style={{ width: '200px', height: '200px', margin: 'auto' }}
              />
            </div>
            ) : (
          <>
              <div className="divOne_siginin_Tabs">
                {/* sigin in */}
                <div className="divOne_siginin_Tabs">
                  {isUserLoggedIn ? (
                    <div className="userDetails">
                    <h1>User Details</h1>
                  </div>
                  ) : (
                    <div className="sigiin_tabs_tags">
                      <div
                        className="sigiin_tabs_tags_div1"
                        onClick={() => handleTabClick(1)}
                        style={{
                          borderTopLeftRadius: "30px",
                          cursor: "pointer",
                          background: activeTab === 1 ? "linear-gradient(to right, #2191D0, #00D0D2)" : "white",
                          color: activeTab === 1 ? "white" : "black",
                          display: "inline-block",
                        }}
                      >
                        <p style={{ fontWeight: activeTab === 1 ? "bold" : "normal", margin: 0, paddingTop: "20px" }}>
                          Sign In
                        </p>
                      </div>

                      <div
                        className="sigiin_tabs_tags_div1"
                        onClick={() => handleTabClick(2)}
                        style={{
                          borderTopRightRadius: "30px",
                          cursor: "pointer",
                          background: activeTab === 2 ? "linear-gradient(to right, #2191D0, #00D0D2)" : "white",
                          color: activeTab === 2 ? "white" : "black",
                          display: "inline-block",
                        }}
                      >
                        <p style={{ fontWeight: activeTab === 2 ? "bold" : "normal", margin: 0, paddingTop: "20px" }}>
                          Continue As Guest
                        </p>
                      </div>
                    </div>
                  )}
                </div>

                {/* continue as a guest */}
                <div className="Content_For_sigin_guest">
                      <div className="tabOne_signin">
                        {activeTab === 1 && (
                          <div className="activeTab_Main_continer">
                            {/* country code select tag */}
                            <div className="input_country_number">
                              {showOTPSection ? (
                                <div className="otp_section">
                                  <div className="otp_section_heading">
                                    <h1>Verify With OTP</h1>
                                    <p>Sent To +91 XXXX-XXX-{phoneNumber.slice(-3)}</p>
                                  </div>
                                  <div className="otp_input_container">
                                    {[0, 1, 2, 3, 4, 5].map((index) => (
                                      <input
                                        key={index}
                                        id={`otpInputBox${index}`}
                                        type="text"
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="otp_input_box"
                                        maxLength="1"
                                        value={enteredOTP[index]}
                                        onChange={(e) =>
                                          handleOTPChange(index, e.target.value)
                                        }
                                      />
                                    ))}
                                  </div>
                                  {/* <div className="resendOption">
                                    <h1>Resend OTP In 08 Sec</h1>
                                  </div> */}

                                  <div className="ResendOtp_Div">
                                      {showResendText ? (
                                        <div className="resendOption" onClick={handleResendOTP}>
                                          <h1>Resend OTP</h1>
                                        </div>
                                      ) : (
                                        <div className="resendOption">
                                          <h1>{resendTimer} sec</h1>
                                        </div>
                                      )}
                                    </div>
                                </div>
                              ) : (
                                <>
                                  <div className="main_Sigin_Inputs">
                                    <div className="headingMobieleNumber">
                                      <p>Mobile Number</p>
                                    </div>

                                    <div className="main_Sigin_Inputs_parents">
                                      <div className="custom_select_confirm">
                                        {/* <div className={`select_header_confirm ${ isOpen ? "open" : ""}`} onClick={toggleOptions}>
                                          <div className="select_button_confirm">
                                            <p>{selectedOption}</p>
                                            <span className={`select_button_Unicode_confirm ${   isOpen ? "rotate" : ""}`}> &#62;</span>
                                          </div>
                                        </div>
                                        <div className={`options_confirm ${isOpen ? "open" : ""}`}>
                                          {options.map((option, index) => (
                                            <div className="option" key={index} onClick={() => handleOptionClick(option)}>{option}</div>
                                          ))}
                                        </div> */}
                                        <Select
                                            value={selectedOption}  
                                            onChange={handleOptionClick}  
                                            options={countryCodes}   
                                            // placeholder="+91"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "white" : "white",
                                                borderColor: state.isFocused ? "white" : "white",  
                                                boxShadow: "none", 
                                                '&:hover': { borderColor: "white" }, 
                                                width:'140px',
                                                color: "#101010",
                                                borderRadius: "16px", 
                                                padding: "14px", 
                                                "@media screen and (max-width: 767px)": {
                                                  width:'82px',
                                                  padding: "7px",
                                                  borderRadius: "10px",
                                                },
                                                "@media screen and (min-width: 768px) and (max-width: 1023px)": {
                                                  width:'90px',
                                                  padding: "8px",
                                                  borderRadius: "10px",
                                                },
                                              }),
                                              menu: (provided, state) => ({
                                                ...provided,
                                                border: "none",
                                                boxShadow: "none",
                                                borderRadius: "16px",
                                                boxShadow:'-1px 4px 99px #0000001a',
                                              }),
                                              option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused && "white",
                                                color: "#101010",
                                                opacity: "0.8",
                                                fontSize: "1rem",
                                                textAlign:'center',
                                                fontFamily:'Poppins-Regular',
                                                padding: "16px", 
                                                borderBottom: state.isLastOption ? 'none' : '1px solid rgba(112, 112, 112, 0.2)'
                                              }),
                                              dropdownIndicator: (base) => ({
                                                ...base,
                                                color: "black",
                                                opacity: "1",
                                              }),
                                              singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "white" : "white",
                                                border: 0,
                                                color: "#101010",
                                                fontSize: "1.25rem",
                                                "@media screen and (max-width: 767px)": {
                                                  fontSize: "0.875rem", 
                                                },
                                              })
                                            }}
                                          />
                                      </div>

                                      {/* number input */}
                                      <div className="phonenumber_input">
                                        <div className="custon_input_confirm">
                                          <input type="text" placeholder="Enter Mobile Number" value={phoneNumber} onChange={handleInputChange}/>
                                          <span id="tickMark">&#10004;</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                            {/* button get otp */}
                            <div className="buttonDivName">
                              {showOTPSection ? (
                                <button className="Navbar_Login_Button_Confimation" type="button" onClick={handleOTPSubmit}>Verify </button>
                              ) : (
                                <button className="Navbar_Login_Button_Confimation" type="button" onClick={handleGetOTPClick}>Get Otp</button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="tabtwo_continue_as_guest">
                        {activeTab === 2 && (
                          <div className="main_guest_form">
                            <form onSubmit={handleSubmit} className="formSubmit">
                              <div className="label_guest_form">
                                <label htmlFor="firstName">First Name</label>
                              </div>
                              <input type="text" id="input1" className="input_guest" name="first_name" placeholder="First Name" value={pageFormData.first_name} onChange={handleChange} required/>
                              <br />
                              <div className="label_guest_form">
                                <label htmlFor="lastName">Last Name</label>
                              </div>
                              <input type="text" id="input2" className="input_guest" name="last_name" placeholder="Last Name"  value={pageFormData.last_name} onChange={handleChange} required/>
                              <br />
                              <div className="label_guest_form">
                                <label htmlFor="email">Email Address</label>
                              </div>
                              <input type="email" id="input3" className="input_guest" name="user_email" placeholder="Email Address" value={pageFormData.user_email} onChange={handleChange} required/>
                              <br />
                              <div className="label_guest_form">
                                <label htmlFor="mobileNumber">Mobile Number</label>
                              </div>
                              <div className="guest-add-country-code">
                              <Select
                                            value={selectedOptionGuest}  
                                            onChange={handleOptionClickGuest}  
                                            options={countryCodesGuest}   
                                            // placeholder="+91"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                // borderColor: state.isFocused ? "white" : "white",
                                                borderColor: state.isFocused ? "white" : "white",  
                                                boxShadow: "none", 
                                                '&:hover': { borderColor: "white" }, 
                                                width:'140px',
                                                color: "#101010",
                                                // fontSize: "1.375rem",
                                                borderRadius: "16px", 
                                                padding: "14px", 
                                                "@media screen and (max-width: 767px)": {
                                                  width:'74px',
                                                  padding: "4px",
                                                  borderRadius: "10px",
                                                  // fontSize: "0.625rem", 
                                                },
                                                "@media screen and (min-width: 768px) and (max-width: 1023px)": {
                                                  width: '74px',
                                                  padding: "3px",
                                                  borderRadius: "10px",
                                                },
                                              }),
                                              menu: (provided, state) => ({
                                                ...provided,
                                                border: "none",
                                                boxShadow: "none",
                                                borderRadius: "16px",
                                                
                                                boxShadow:'-1px 4px 99px #0000001a'
                                              }),
                                              option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isFocused && "white",
                                                color: "#101010",
                                                opacity: "0.8",
                                                fontSize: "1rem",
                                                textAlign:'center',
                                                fontFamily:'Poppins-Regular',
                                                padding: "16px", 
                                                borderBottom: state.isLastOption ? 'none' : '1px solid rgba(112, 112, 112, 0.2)'
                                              }),
                                              dropdownIndicator: (base) => ({
                                                ...base,
                                                color: "black",
                                                opacity: "1",
                                              }),
                                              singleValue: (baseStyles, state) => ({
                                                ...baseStyles,
                                                borderColor: state.isFocused ? "white" : "white",
                                                border: 0,
                                                color: "#101010",
                                                fontSize: "1.25rem",
                                                "@media screen and (max-width: 767px)": {
                                                  fontSize: "0.875rem", 
                                                },
                                                "@media screen and (min-width: 768px) and (max-width: 1023px)": {
                                                  fontSize: "0.875rem", 
                                                },
                                              })
                                            }}
                                  />
                                <input type="tel" id="input4" className="input_guest" name="user_mobilenumber" placeholder="Mobile Number" value={pageFormData?.user_mobilenumber} disabled={userPData?.mobile} onChange={handleChange} required/>
                              </div>
                              <br />
                              <div className="AgreeTerms_checkbox">
                                <div className="DeskTopView_ConfirmationPage_Terms">
                                  <div className="Confimation_AgreeTerms_checkbox">
                                    <input type="checkbox" id="agree" name="agree" onChange={handleChange}/>
                                    <p onClick={handleParagraphClick}>I agree to the{" "}<span onClick={openModal} style={{ cursor: "pointer", color: "#2191d0", marginRight: "5px",}}>Terms and Conditions</span>of M2M Ferries</p>
                                    <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Terms and Conditions Modal" className="custom-modal-TermsAndCondition">
                                      <div className="TermsAndCoditon_M2M">
                                        <h2>Terms & Conditions</h2>
                                        <div className="TermsAndCodition_Container">
                                          <div className='Terms_Condition_Heading_content' dangerouslySetInnerHTML={{__html:TemsData,}}/>
                                            <div className="BtnTemrsAndCondition"><button onClick={closeModal}>Back</button></div>
                                        </div>
                                      </div>
                                    </Modal>
                                  </div>
                                </div>
                                <div className="MobileView_ConfirmationPage_Terms">
                                  <div className="Confimation_AgreeTerms_checkbox">
                                    <input type="checkbox" id="agree" name="agree" onChange={handleChange}/>
                                    <p>I agree to the{" "}<span  onClick={handleOpenPopupMobile}  style={{ cursor: "pointer", color: "#2191d0",}}>Terms and Conditions</span> of M2M Ferries</p>
                                    {isMobileOpenConfirm && (
                                      <div className="popupmobile_ConfirmationPage" onClick={handleClosePopupMobile}>
                                        <div className="TermsAndCondion_Popup_Content">
                                          <div className="TermsAndCodition_Popup_Container">
                                            <div className="TermsAndCodition_Popup_Heading">
                                              <h1>Terms And Conditions</h1>
                                              <p onClick={handleClosePopupMobile}>x</p>
                                            </div>
                                            <div className='TAndC_Popup_Content' dangerouslySetInnerHTML={{__html:TemsData,}}/>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <br />

                              {/* <Link to={"/confirm"} className="link-without-underline"> */}
                              <div className="guest_book_button">
                                <button className="Navbar_Login_Button_Confimation_BookNow" disabled={BtnDissabled} type="submit" onClick={bookNowBtnClick}>Book Now</button>
                              </div>
                              {/* </Link> */}
                            </form>
                          </div>
                        )}
                      </div>
                </div>
              </div>
          </>
        )}

        {/* <KeyInformationDesktop /> */}
        {isMobile ? "" : <KeyInformationDesktop />}
      </div>
      <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isBrowserAlerts}</p>
           <div className="ModalButton"><button onClick={closeBrowserModal}>Ok</button></div>
        </Modal> 
        <Modal isOpen={isWrongNumberAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
           <p>{isWrongNumberAlerts}</p>
           <div className="ModalButton"><button onClick={closeWrongNumberModal}>Ok</button></div>
        </Modal> 
    </div>
  );
};

export default PassangerDetail;
