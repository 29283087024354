import React, { useEffect, useRef, useState } from "react";
import "../../Pages/ThankYouPage/ThankYouPage.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StaticDataService from "../../services/staticData";
import {M2MAImageUrl } from "../../services/staticData";

const SuccessPage = ({ booking_id, booking_amount }) => {
  
  const [showTick, setShowTick] = useState(false);
  const [exploreData, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const sliderRef = useRef();

  useEffect(() => { //
    if(process.env.REACT_APP_ENV === "production"){   
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '228849599503791');
        fbq('track', 'PageView');
        fbq('track', 'Purchase', {currency: "INR", value: ${booking_amount}});
      `;
      document.body.insertBefore(script, document.body.firstChild);

      // Cleanup the script when the component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  useEffect(() => {
    
    StaticDataService.explorePosts()
    .then((response) => {
      if (response && response.explorePosts) {

        // const posts = processExploreData(response.explorePosts);
        const posts = processExploreData(response.explorePosts).filter(post => 
            post.categories.some(category => category.id === 1)
        ); 
        setData(posts);
      } else {
            console.error("Error in response:", response);
        }
    })
    .catch((err) => {console.error("API Error:", err);})
    .finally(() => {});

    const startAnimation = () => {
      setShowTick(false);
      const loader = document.getElementById("loader");
      loader.style.background = "transparent";
      setTimeout(() => {
        setShowTick(true);
        loader.style.background = "white";
      }, 3000);
    };
    startAnimation();
    const intervalId = setInterval(startAnimation, 6000);
    return () => clearInterval(intervalId);
    
  }, [booking_id]);

  const processExploreData = (data) => {
    return data.map(post => ({
      image: M2MAImageUrl + post.hero_image.replace('public/',""),
      //redirectTo: post.redirect_url,
      //display_order: post.display_order,
      heading: post.title,
      id: post.id,
      rating:post.rating,
      know_more_link:post.app_redirect_link,
      description:post.description,
      categories: post.categories,
    }));
  };
  

  //   explore mandwa
  
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    afterChange: (currentSlide) => {
      setCurrentPage(currentSlide + 1);
    },
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: (currentSlide) => {
            setCurrentPage(currentSlide + 1);
          },
        },
      },
    ],
  };
  
  const totalPage = exploreData ? exploreData.length : 0;


  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  

  const openGetDirection = () => {const urlToNavigate = "https://www.google.com/maps/place/18%C2%B057'27.5%22N+72%C2%B050'51.3%22E/@18.9576389,72.8475833,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.9576389!4d72.8475833?hl=en-IN&entry=ttu";window.location.href = urlToNavigate;};
  const openGetSecondAdress = () => {const urlToNavigate = "https://www.google.com/maps/place/18%C2%B048'19.9%22N+72%C2%B052'52.9%22E/@18.8055278,72.8813611,17z/data=!3m1!4b1!4m4!3m3!8m2!3d18.8055278!4d72.8813611?hl=en-IN&entry=ttu";window.location.href = urlToNavigate;};

  const handleItemClick = (know_more_link) => {
    
    if (know_more_link) {
        window.location.href = know_more_link;
      } 
      else {}
  };

  return (
    <>
    <div className="TahnYouPAge_Continer">
          <div className="TahnYouPAge_Continer_Div1">
            <div className="Div1_roundRightTick">
              <div className="loader-container">
                <div
                  className="loader"
                  id="loader"
                  style={{ background: "white" }}
                ></div>
                {showTick && (
                  <div className="tick" id="tick">
                    &#10003;
                  </div>
                )}
              </div>
            </div>
            <div className="Div1_Tahnkyou_heading">
              <h1>Your Booking Is Confirmed!</h1>
            </div>
          </div>
          <div className="TahnYouPAge_Continer_Div2">
            <h1>Booking ID : {  booking_id }</h1>
            <p>
              See You Soon! Thank you for choosing M2M Ferries! The booking
              confirmation has been sent to your email. For any further
              assistance, kindly email us at{" "}
              <a href="mailto:support@m2mferries.com"> support@m2mferries.com</a> or call <a href="callto:+918291902662">+918291902662</a>.
            </p>
            <div className="TahnYouPAge_Continer_Div2_buttons">
              <div className="thankbtn1">
                <button className="ThankYou_Button" type="button">
                  View on app
                </button>
              </div>
              {/* <div className="thankbtn2">
                <button className="ThankYou_Button" type="button">
                  Download ticket
                </button>
              </div> */}
            </div>
          </div>
        </div>

        {/* mapsData */}
        <div className="Map_ThankYou_Section">
          <div className="Map_ThankYou_Section_heading">
            <h1>How To Get There ?</h1>
          </div>
          <div className="Map_ThankYou_Section_TwoDiv">
            <div className="Map_ThankYou_Section_TwoDiv1" onClick={openGetDirection}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3773.406676327527!2d72.8475833!3d18.9576389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDU3JzI3LjUiTiA3MsKwNTAnNTEuMyJF!5e0!3m2!1sen!2sin!4v1728976820534!5m2!1sen!2sin" width="755" height="319" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              <div className="heading_thankYou">
                <h1>M2M Mumbai Terminal </h1>
                <p>Get Direction</p>
              </div>
              <div className="heading_thankYou_pTag">
                <p>
                  M2M Mumbai Terminal Ferry Wharf, Bhaucha Dhakka Mumbai Port, 
                  Mumbai - 400010
                </p>
              </div>
            </div>
            <div className="Map_ThankYou_Section_TwoDiv1" onClick={openGetSecondAdress}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3776.834493337124!2d72.88136109999999!3d18.805527799999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDQ4JzE5LjkiTiA3MsKwNTInNTIuOSJF!5e0!3m2!1sen!2sin!4v1728976948011!5m2!1sen!2sin" width="755" height="319" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              <div className="heading_thankYou">
                <h1>M2M Mandwa Terminal</h1>
                <p>Get Direction</p>
              </div>
              <div className="heading_thankYou_pTag">
                <p>
                  M2M Mandwa Terminal, Ropax Service Jetty, Mandwa Jetty Road, 
                  Mandwa - 402201, Maharashtra
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* explore Mandwa */}
        {exploreData ? (
          <div className="mandwa_SectionThankyouPage">
          <div className="mandwa_SectionThankyouPage_heading">
            <h1>Explore Mandwa</h1>
          </div>

          <div className="slider_container">
            <Slider ref={sliderRef} {...settings}>
              {exploreData.map((item, index) => (
                <div key={index} className="slides" onClick={() => handleItemClick(item.know_more_link)}>
                  <div className="border-radius image-div">
                    <img src={item.image} alt={item.heading} />
                  </div>
                  <h2>{item.heading}</h2>
                  {/* <p dangerouslySetInnerHTML={{__html: item.description}}></p> */}
                  {item.description && typeof item.description === "string" && item.description.trim() !== "" ? (
                    <p dangerouslySetInnerHTML={{ __html: item.description.length > 100 
                      ? `${item.description.substring(0, 170)}... <a href="${item.know_more_link}" target="_blank" rel="noopener noreferrer">Read More</a>` 
                      : item.description 
                    }} />
                  ) : null}
                </div>
              ))}
            </Slider>
              <div className="pagination_Explore_about">
                  <div className="prev_button_eight_about" onClick={goToPrev} disabled={currentPage === 1}>
                     <img src="/assets/img/Partners/arrow.png" alt="dcs" />
                  </div>

                  <div className="page_indicator_about">
                     {String(currentPage).padStart(2, '0')}/{String(totalPage).padStart(2, '0')}
                  </div>
                  <div className="next_button_eight_about" onClick={goToNext} disabled={currentPage === totalPage} >
                    <img src="/assets/img/Partners/arrow.png" alt="dcs" />
                  </div>
            </div>
          </div>
        </div>
        ):null}
        

        {/* our parents */}
        {/* <div className="Our_Partners_thankyouPage">
          <Parents_thank />
        </div> */}
        </>
  )
}

export default SuccessPage
