import React, { useRef, useState, useEffect } from "react";
import "./NewWidget.css";
import TripDataService from "../../services/TripData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Modal from 'react-modal';
import StaticDataService from '../../services/staticData'
import { set } from "date-fns";
import { useLocation } from "react-router-dom";

const NewWidget = () => {
  Modal.setAppElement('#root');

  //alert UseState start
  const [isAlertsOpen, setAlertsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);

  //Tabs UseState start
  const [showTab1, setShowTab1] = useState(true);
  const [showroundbtn, setRoundbtn] = useState(false);
  const [showonwardbtn, setOnwardbtn] = useState(true);
  const [tripType, setTripType] = useState("onward");

  // Form To Tag ustate  start
  const [fromDropdownOpen, setFromDropdownOpen] = useState(false);
  const [toDropdownOpen, setToDropdownOpen] = useState(false);
  const [selectedFromOption, setSelectedFromOption] = useState("Mumbai");
  const [selectedToOption, setSelectedToOption] = useState("Mandwa");
  const [arrowRotated, setArrowRotation] = useState([false, false]);
  const [fromToFlipped, setFromToFlipped] = useState(false);
  const [mobilePopupOpen, setMobilePopupOpen] = useState(false);
  const [selectedMobileOption, setSelectedMobileOption] = useState("");

  // Date Picker USeState Departure and Return
  const [endsailingdate, setEndsailingDate] = useState([]);
  const [availableDates, setAvailableDates] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const location = useLocation();
  const [selectedDateNew, setSelectedDateNew] = useState(new Date());
  
  // const [selecteRetdDateNew, setSelectedRetDateNew] = useState(null);

  // Passanger  and Vechicle Usestate start
  const [activeElement, setActiveElement] = useState(null);
  const [hiddenElementCounts, setHiddenElementCounts] = useState({
    elementOne: { Adultcount: 0, Childcount: 0, Petcount: 0,},
    elementTwo: { Carcount: 0, Scootercount: 0, Cyclecount: 0, Buscount: 0,},
  });
  const [arrowRotationPass, setArrowRotationPass] = useState({
    elementOne: false,
    elementTwo: false,
  });
  const [direction, setDirection] = useState("down");
  const [formToDoirection, setFormToDoirection] = useState("downrd");
  const [selectedSailingDate, setSelectedSailingDate] = useState(new Date());

  const [alerts, setAlerts] = useState(new Date());

  //FOR URL DATES FROM SCHEDULE PAGE

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sailingDateParam = params.get('sailing_date');
    if (sailingDateParam) {
      const parsedDate = new Date(decodeURIComponent(sailingDateParam));
      //console.log(parsedDate);
      //selectedSailingDate? setSelectedDateNew(selectedSailingDate) : setSelectedDateNew(selectedDateNew);
      setSelectedSailingDate(parsedDate);
      setSelectedDateNew(parsedDate);
    }
  }, [location]);


  
  // FOR ALERTS
  useEffect(()=>{
    StaticDataService.ServiceInfo()
    .then((response) => {
        var alerts1 = []
        response.infoData.map(alert => {
          alerts1.push(alert.message);
        });
        setAlerts(alerts1);
    })

}, []);

  //  alerts functionality start
  const toggleAlerts = () => {
    setAlertsOpen(!isAlertsOpen);
  };
  const overlayRef = useRef(null);

  const toggleContents = () => {
    if(showContent) {
      document.body.classList.remove('noscroll');
    }
    else{
      document.body.classList.add('noscroll');
    }
    setShowContent(!showContent);
  };

  const closeContent = (e) => {
    setShowContent(false);
    // if (overlayRef.current && overlayRef.current === e.target) {
    //   setShowContent(false);
    // }
  };

  //Tab butons change functionality
  const handleTab1CheckboxChange = () => {
    setRoundbtn(true);
    setOnwardbtn(false);
    setTripType("return");
  };

  const handleTab2CheckboxChange = () => {
    setRoundbtn(false);
    setOnwardbtn(true);
    setEndDate(null)
    setTripType("onward");
  };


// New USeEffct
  useEffect(() => {
    localStorage.removeItem("formData");
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    const rawData = JSON.stringify({
      //month,
      year,
      live_data: true,
    });
  
    TripDataService.fetchTrips(rawData)
      .then((response) => {
        if (response.status !== "error") {
          const endtripavailable = response.sailings.slice(-1).pop();
          setEndsailingDate(endtripavailable.sailing_date); // for disable dates if no trips available
          const uniqueTags1 = [];
          response.sailings.map(availableDate => {
            if (uniqueTags1.indexOf(availableDate.sailing_date) === -1) {
              uniqueTags1.push(new Date(availableDate.sailing_date))
            }
          });
          setAvailableDates(uniqueTags1); // Trips data for a year 

          // set in loaclstorage
          localStorage.setItem('avilableDates',JSON.stringify(uniqueTags1));

          // console.log('Available dates stored in localStorage:', uniqueTags1);

          
          // Check if todays date is available or not

          const today = new Date().toISOString().slice(0, 10);

          // Remove duplicates from the dates array
          const uniqueDates = [...new Set(uniqueTags1)];

          // Check if today's date is in the uniqueDates array
          const isTodayAvailable = uniqueDates.includes(today);

          if (isTodayAvailable) {
            setSelectedDateNew(today); // Set today's date as default if available
          } else {
            // Find the next available date after today
            const nextAvailableDate = uniqueDates.find(date => date > today);
            if (nextAvailableDate) {
              setSelectedDateNew(nextAvailableDate);
            } else {
              // If no available dates after today, set default to the first date in the array
              if (uniqueDates.length > 0 && !selectedSailingDate) {
                setSelectedDateNew(uniqueDates[0]);
              }
            }
          }
           // Check if todays date is available or not
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, []);

  // departre date
  const [startDate, setStartDate] = useState(selectedDateNew);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = date => {
    // if(!availableDates.includes(date)){
    //   setIsBrowserAlerts("Trips are not available on this date. Please select another date.");
    //   return;
    // }
    setStartDate(date);
    setSelectedDateNew(date);
    setEndDate(null); // Reset return date when departure date changes

  };
  
  // return Date
  const handleEndDateChange = date => {
    setEndDate(date);
    // setSelectedRetDateNew(date);
    setRoundbtn(true);
    setOnwardbtn(false);
    setTripType("return");
  };


  const toggleFromDropdown = () => {
    if (window.innerWidth > 600) {
      setFromDropdownOpen(!fromDropdownOpen);
    } else {
      openMobilePopup();
    }
    const mainContentRect = document
      .querySelector(".tabs-container")
      .getBoundingClientRect();
    const screenHeight = window.innerHeight;

    setFormToDoirection(
      mainContentRect.top > screenHeight / 2 ? "upward" : "'downrd'"
    );
    setFromDropdownOpen(!fromDropdownOpen);
    setToDropdownOpen(false);
    setActiveElement(false);
    setArrowRotation([!fromDropdownOpen, arrowRotated[1]]);
  };

  const toggleToDropdown = () => {
    if (window.innerWidth > 600) {
      setToDropdownOpen(!toDropdownOpen);
    } else {
      openMobilePopup();
    }
    const mainContentRect = document
      .querySelector(".tabs-container")
      .getBoundingClientRect();
    const screenHeight = window.innerHeight;

    setFormToDoirection(
      mainContentRect.top > screenHeight / 2 ? "upward" : "downrd"
    );
    setToDropdownOpen(!toDropdownOpen);
    setFromDropdownOpen(false);
    setArrowRotation([arrowRotated[0], !toDropdownOpen]);
  };

  const toggleFromTo = () => {
    setSelectedFromOption(fromToFlipped ? "Mumbai" : "Mandwa");
    setSelectedToOption(fromToFlipped ? "Mandwa" : "Mumbai");
    setArrowRotation([!fromToFlipped, !fromToFlipped]);
    setFromToFlipped(!fromToFlipped);
  };

  const handleFromChange = (option) => {
    setSelectedFromOption(option);
    setSelectedFromOption(option);
    setSelectedToOption(option === "Mandwa" ? "Mumbai" : "Mandwa");
    if (window.innerWidth > 600) {
      toggleFromDropdown();
    } else {
      closeMobilePopup();
    }
  };

  const handleToChange = (option) => {
    setSelectedToOption(option);
    setSelectedToOption(option);
    setSelectedFromOption(option === "Mandwa" ? "Mumbai" : "Mandwa");
    if (window.innerWidth > 600) {
      toggleToDropdown();
    } else {
      closeMobilePopup();
    }
  };

  const options = ["Mumbai", "Mandwa"];

  const openMobilePopup = () => {
    setMobilePopupOpen(true);
    document.body.classList.add('noscroll');
  };

  const closeMobilePopup = () => {
    setMobilePopupOpen(false);
    document.body.classList.remove('noscroll');
  };
  //  Form_TO functionality Mobile Include End

  // Passanger & Vechicle functionality  Mobile Include Tag start

  const toggleContent = (element) => {
    setToDropdownOpen(false);
    setFromDropdownOpen(false);
    if (element === activeElement) {
      setActiveElement(null);
      setArrowRotationPass((prevRotation) => ({
        ...prevRotation,
        [element]: false,
      }));
    } else {
      // Otherwise, show the clicked element.
      setActiveElement(element);
      setArrowRotationPass({
        elementOne: element === "elementOne",
        elementTwo: element === "elementTwo",
      });
    }
    const mainContentRect = document
      .querySelector(".tabs-container")
      .getBoundingClientRect();
    const screenHeight = window.innerHeight;

    if (mainContentRect.top > screenHeight / 2) {
      setDirection("up");
    } else {
      setDirection("down");
    }
  };
  const handleIncrement = (element, className, event) => {
    //console.log(element, className, event);
    const updatedCounts = { ...hiddenElementCounts };
    updatedCounts[element][className] += 1;
    if(updatedCounts['elementOne']['Adultcount'] > 25){
      setIsBrowserAlerts(`For Bookings more than 25 passengers, Please contact our booking helpline on 8291902662`);
      updatedCounts['elementOne']['Adultcount'] = 25;
      return false;
    }
    if(updatedCounts['elementOne']['Childcount'] > 15){
      setIsBrowserAlerts(`For Bookings more than 15 infants, Please contact our booking helpline on 8291902662`);
      updatedCounts['elementOne']['Childcount'] = 15;
      return false;
    }
    //console.log("updatedCounts : ", updatedCounts['elementOne']['Adultcount']);
    setHiddenElementCounts(updatedCounts);
    event.stopPropagation();
  };

  const handleDecrement = (element, className, event) => {
    const updatedCounts = { ...hiddenElementCounts };
    if (updatedCounts[element][className] > 0) {
      updatedCounts[element][className] -= 1;
    }
    setHiddenElementCounts(updatedCounts);
    event.stopPropagation();
  };

  // Passanger & Vechcal Tag end Mobile
  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    if(popupVisible)
    {
      document.body.classList.remove('noscroll');
    }
    else{
      document.body.classList.add('noscroll');
    }
    setPopupVisible(!popupVisible);
  };
  // 2nd poppup vechicals  Mobile
  const [popupVisibleTwo, setPopupVisibleTwo] = useState(false);
  const togglePopupTwo = () => {
    if(popupVisibleTwo)
    {
      document.body.classList.remove('noscroll');
    }
    else{
      document.body.classList.add('noscroll');
    }
    setPopupVisibleTwo(!popupVisibleTwo);
  };

  // Passanger & Vechicle functionality  Mobile Include Tag End


  // for alerts open Poupup
  const [isOpen, setIsOpen] = useState(false);
  const [isBrowserAlerts, setIsBrowserAlerts] = useState();

  const handleDivBusClick = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setIsBrowserAlerts('');
  };

  // localStoragag add to data
  function SearchTripsHome() {
    const passengersData = hiddenElementCounts.elementOne;
    const vehicleData = hiddenElementCounts.elementTwo;
    // const selectedDateNew1 = moment(selectedDateNew).format("MM/D/YYYY");
    // const selecteRetdDateNew1 = moment(endDate).format("MM/D/YYYY");
    const selectedDateNew1 = selectedDateNew ? moment(selectedDateNew).format("MM/DD/YYYY") : "";
    const selecteRetdDateNew1 = endDate ? moment(endDate).format("MM/DD/YYYY") : "";
    const abbreviationFormTo = { Mumbai: "MUM", Mandwa: "MWA",};
    const selectedFromAbbreviation = abbreviationFormTo[selectedFromOption];
    const selectedToAbbreviation = abbreviationFormTo[selectedToOption];
    const endsellingDateNewWidget = endsailingdate;

    var AdultCnt = passengersData.Adultcount;
    var ChildCnt = passengersData.Childcount;
    var PetCnt = passengersData.Petcount;
    
    console.log(AdultCnt, ChildCnt);

    var CarCnt = vehicleData.Carcount;
    var ScooterCnt = vehicleData.Scootercount;
    var CycleCnt = vehicleData.Cyclecount;
    var BusCnt = vehicleData.Buscount;

    const passengerCnt = AdultCnt + ChildCnt + PetCnt;
    const VehicleCnt = CarCnt + ScooterCnt + CycleCnt + BusCnt;
    var allowNext = true;

    // console.log(selectedDateNew1,"selectedDateNew1")
    // console.log(selecteRetdDateNew1,"selecteRetdDateNew1")

    if (selectedDateNew === "") {
      setIsBrowserAlerts("Please select a Departure Date.");
      allowNext = false;
    }
    if (tripType === "return") {
      if (!selecteRetdDateNew1) {
        //console.log(tripType,selecteRetdDateNew1,"triptype")
        setIsBrowserAlerts("Please select a Return Date.");
        allowNext = false;
      }
    }

    if (AdultCnt < 1) {
      setIsBrowserAlerts("Please enter the number of passengers to proceed.");
      allowNext = false;
    }
    if(AdultCnt > 25)
    {
      setIsBrowserAlerts(`For Bookings more than 25 passengers, Please contact our booking helpline on 8291902662`);
      return false;
    }

    if(ChildCnt > 15)
    {
      setIsBrowserAlerts(`For Bookings more than 15 infants, Please contact our booking helpline on 8291902662`);
      return false;
    }
    if (VehicleCnt > AdultCnt) {
      setIsBrowserAlerts("Please adjust your selection so that the number of vehicles does not exceed the number of passengers.");
      allowNext = false;
    }
    const returnJourney = {
      selecteRetdDateNew1,
      selectedFromAbbreviation,
      selectedToAbbreviation,
      passengersData,
      vehicleData,
      endsellingDateNewWidget
    };

    const onwardJourney = {
      selectedDateNew1,
      selectedFromAbbreviation,
      selectedToAbbreviation,
      passengersData,
      vehicleData,
      endsellingDateNewWidget
    };
    var formDataToStore;
    if (tripType === "onward") {
      formDataToStore = {
        triptype: tripType,
        onwardJourney,
      };
    } else {
      formDataToStore = {
        triptype: tripType,
        onwardJourney,
        returnJourney,
      };
    }
    localStorage.setItem("formData", JSON.stringify(formDataToStore));
    if(allowNext)
      window.location = "/onward-departure";
  }


 
  // AnyWere Clikc dropdown will close it
  const fromDropdownRef = useRef(null);
  const toDropdownRef = useRef(null);
  const passengerTagRef = useRef(null);
  const vehicleTagRef = useRef(null);

  // Function to close dropdowns and date pickers
  const closeDropdownsAndDatePickers = () => {
    setFromDropdownOpen(false);
    setToDropdownOpen(false);
    setActiveElement(null);
  };

  // Effect to add a global click event listener
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        fromDropdownRef.current &&
        !fromDropdownRef.current.contains(event.target) &&
        toDropdownRef.current &&
        !toDropdownRef.current.contains(event.target) &&
        passengerTagRef.current &&
        !passengerTagRef.current.contains(event.target) &&
        vehicleTagRef.current &&
        !vehicleTagRef.current.contains(event.target)
      ) {
        closeDropdownsAndDatePickers();
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  /* if(selectedSailingDate){
    setSelectedDateNew(selectedSailingDate); 
  } */
  //const newSelectedTripDate = selectedSailingDate? selectedSailingDate : selectedDateNew;
  
  //selectedSailingDate? setSelectedDateNew(selectedSailingDate) : setSelectedDateNew(selectedDateNew);
  //console.log("endsailingdate : ", endsailingdate);
  

  return (
    <div>
      <div className="Desktop_View_Vegit">
        <div className="desktop_view_alert">
          <div className={`accordions ${isAlertsOpen ? "open" : ""}`}>
            <div
              className="importAlertsingleAlert" style={{backgroundColor: isAlertsOpen? "#d4f4ff": "#d4f4ff",}}>
                {/* #FFFFFF99 */}
              <img src="/assets/img/Widget/DangerCircle.png" alt="asad" />
              {/* <p>Schedule is live till {moment(endsailingdate, ['MM/DD/YYYY']).format("Do MMM YYYY")}</p> */}
              <p>{alerts[0]}</p>
            </div>
            <div className="accordion_header" onClick={toggleAlerts}>
              <span className={`arrow_icon_accordian ${isAlertsOpen ? "open" : ""}`} >
                <div>
                  {isAlertsOpen ? (<h3>&#10005;</h3>) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="8.41" viewBox="0 0 16.82 8.41">
                      <path id="Path" d="M19,15,12,9,5,15" transform="translate(-3.59 -8)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    </svg>
                  )}
                </div>
              </span>
            </div>
            {isAlertsOpen && (
              <div className="accordion_content">
                {alerts.slice(1).map((alert, index) => (
                    <div className="importAlert" key={index}>
                      <img src="/assets/img/Widget/DangerCircle.png" alt="asad" />
                      <p>{alert}</p>
                    </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="tabs-container">
          <div className="tabs">
            <div className="Widget_checkboxs" onClick={() => handleTab2CheckboxChange()}>
              <input type="checkbox" onChange={handleTab2CheckboxChange} checked={showonwardbtn} className="round-checkbox"/>
              <p> One Way</p>
            </div>
            <div className="Widget_checkboxs" onClick={() => handleTab1CheckboxChange()}>
              <input type="checkbox" checked={showroundbtn} onChange={handleTab1CheckboxChange} className="round-checkbox"/>
              <p> Round Trip</p>
            </div>
          </div>
          <div className="content">
            {showTab1 && (
              <form>
                <div className="Tab_RoundTip">
                  <div className="tab-content ">
                    {/* form_To are start */}
                    <div className="Form_To">
                      <div ref={fromDropdownRef}  className="element_Form_To" style={{ cursor: "pointer" }} >
                        <label className="Form_to_labels">From</label>
                        <div className="custom_dropdown">
                          <div className={`custom_dropdown__toggle ${arrowRotated[0] ? "rotate-arrow" : ""}`}onClick={toggleFromDropdown}>
                            <span className="slectTagNmaes">{selectedFromOption}</span>
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="down-arrow" className="custom_dropdown__arrow" />
                          </div>
                        </div>
                        <div className="Form_Options">
                          {fromDropdownOpen && (
                            <div className="custom_dropdown__content" id="yourFormId"
                              style={{ position: "absolute",
                                top: formToDoirection === "downrd" ? "50%" : "auto",
                                bottom: formToDoirection === "upward" ? "70%" : "auto",
                              }}
                            >
                              {options.map((option, i) => (
                                <div key={option} className={`custom_dropdown__option row${i}`} onClick={() => handleFromChange(option)} >
                                  <p>{option}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="For_To_Logo" style={{ cursor: "pointer" }} onClick={toggleFromTo}   >  
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="19.828" viewBox="0 0 14.828 19.828">
                          <g id="Sort_Horizontal" data-name="Sort Horizontal" transform="translate(-4.586 -1.586)">
                            <path id="Path_3" data-name="Path 3" d="M18,8H6M6,8l4.125-4M6,8l4.125,4" transform="translate(0 -1)" fill="none" stroke="#000" strokeLinecap="round"strokeLinejoin="round" strokeWidth="2"/>
                            <path id="Path_4" data-name="Path 4" d="M6,16H18m0,0-4.125-4M18,16l-4.125,4" fill="none" stroke="#000" strokeLinecap="round"strokeLinejoin="round" strokeWidth="2"/>
                          </g>
                        </svg>
                      </div>
                      <div ref={toDropdownRef} className="element_Form_To" style={{ cursor: "pointer" }} >
                        <label className="Form_to_labels">To</label>
                        <div className="custom_dropdown" id="custom_dropdownTwo" >
                          <div className={`custom_dropdown__toggle ${ arrowRotated[1] ? "rotate-arrow" : "" }`} onClick={toggleToDropdown}>
                            <span>{selectedToOption}</span>
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="down-arrow" className="custom_dropdown__arrow" />
                          </div>
                        </div>
                        <div className="To_Options">
                          {toDropdownOpen && (
                            <div className="custom_dropdown__content" id="yourToContentId"
                              style={{ position: "absolute",
                                top: formToDoirection === "downrd"? "100%" : "auto",
                                bottom: formToDoirection === "upward" ? "70%" : "auto",
                              }}
                            >
                              {options.map((option, i) => (
                                <div key={option} className={`custom_dropdown__option row${i}`} onClick={() => handleToChange(option)} >
                                  <p>{option}</p>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* form_To are close */}

                    {/* date tag start */}
                    <div className="Deaparture_Dates">
                      <div className={`${ showroundbtn ? "DepartureElemntOne": "DepartureElemntOnefull" }`}style={{ cursor: "pointer" }}>
                        <label id="DepTag" className="dparture_date_lebels">Departure</label>
                        <div className="DepartureDateImg">
                          <div><img src="\assets\img\Widget/Date.png" alt="dateImage"/></div>
                        <DatePicker
                                      selected={selectedDateNew}
                                      showPopperArrow={false}
                                      onChange={handleStartDateChange}
                                      minDate={new Date()}
                                      maxDate={new Date(endsailingdate)}
                                      includeDates={availableDates}
                                      customInput={
                                        <p className="SuperDatesCalender" style={{ cursor: "pointer" }}>
                                          {selectedDateNew ? moment(selectedDateNew).format("DD MMM YYYY") : "Add Date"}
                                        </p>
                                      }
                          />
                        </div>
                      </div>
                      {/* Return Date */}
                      <div className='RetutnElemntTwo' style={{ cursor: "pointer", opacity: showroundbtn ? 1 : 1 }}>
                          <label id="DepTag" className="dparture_date_lebels">Return</label>
                          <div className={`DepartureDateImg ${showroundbtn ? "" : ""}`}>
                            <div><img src="\assets\img\Widget/Date.png" alt="dateImage"/></div>
                    
                          <DatePicker
                              selected={endDate}
                              showPopperArrow={false}
                              onChange={handleEndDateChange}
                              minDate={new Date(selectedDateNew || new Date())}
                              maxDate={new Date(endsailingdate)}
                              startDate={selectedDateNew}
                              endDate={endDate}
                              selectsEnd
                              includeDates={availableDates}
                              customInput={
                                <p className="SuperDatesCalenderRet" style={{ cursor: "pointer" }}>
                                  {endDate ? moment(endDate).format("DD MMM YYYY") : "Add Date"}
                                </p>
                              }
                            />


                          </div>
                        </div>
                    </div>
                    {/* Date tag end*/}

                    {/* Passanger elemnet (Elemnt One) start */}
                    <div className={`elements ${ arrowRotationPass.elementOne ? "arrowrotate" : ""}`} style={{ cursor: "pointer" }}>
                      <div className="dyanmicSelect">
                        <label className="labelsForPassanger">Passengers</label>
                        <div ref={passengerTagRef} className="passenger_Tag" onClick={() => toggleContent("elementOne")} >
                          <div className="passenger_Tag_Box1">
                            <div className="imgeTag">
                              <img src="/assets/img/Widget/person.png" alt="person" />
                              <p>{hiddenElementCounts.elementOne.Adultcount}</p>
                            </div>
                            <div className="imgeTag1">
                              <img src="/assets/img/Widget/child.png" alt="child"/>
                              <p>{hiddenElementCounts.elementOne.Childcount}</p>
                            </div>
                            <div className="imgeTag2">
                              <img src="/assets/img/Widget/paw.png" alt="paw" />
                              <p>{hiddenElementCounts.elementOne.Petcount}</p>
                            </div>
                          </div>
                          <div className="passenger_Tag_Box2">
                            <img className={`toggleb_utton_image ${ arrowRotationPass.elementOne ? "rotate_90" : "" }`} src="/assets/img/Widget/Arrow_Down.png"alt="Down Arrow"/>
                          </div>
                        </div>
                      </div>

                      {/* Hidden section for Element One */}
                      {activeElement === "elementOne" && (
                        <div className="elemt_one_hidden" onClick={(e) => e.stopPropagation()} >
                          <div className="additional_content" id="elementOne_hidden"
                            style={{
                              top: direction === "down" ? "107%" : "auto",
                              bottom: direction === "up" ? "80%" : "auto",
                            }}
                          >
                            <div className="Hidden_Info">
                              {/* HiddenDiv1 */}
                              <div className="hiddenInfo_div">
                                <div className="HiddenDiv1">
                                  <div className="hiddeninfo_heading_img"><img src="/assets/img/Widget/person.png" alt="Person" /></div>
                                  <div className="hiddeninfo_heading_tags">
                                    <h5>Adult</h5>
                                    <p>Over 3 Years</p>
                                  </div>
                                </div>
                                <div className="HiddenDiv2" onClick={(e) => e.stopPropagation()} >
                                  <div><p onClick={(e) => handleDecrement( "elementOne", "Adultcount", e )}> - </p></div>
                                  <div><p>{hiddenElementCounts.elementOne.Adultcount}</p></div>
                                  <div><p  onClick={(e) => handleIncrement( "elementOne", "Adultcount", e)}>+</p></div>
                                </div>
                              </div>

                              {/* HiddenDiv2 */}
                              <div className="hiddenInfo_div">
                                <div className="HiddenDiv1">
                                  <div className="hiddeninfo_heading_img"><img src="/assets/img/Widget/child.png" alt="Child" /></div>
                                  <div className="hiddeninfo_heading_tags">
                                    <h5>Infants</h5>
                                    <p>Below 3 Years</p>
                                  </div>
                                </div>
                                <div className="HiddenDiv2" onClick={(e) => e.stopPropagation()} >
                                  <div> <p onClick={(e) => handleDecrement( "elementOne", "Childcount", e ) } > - </p> </div>
                                  <div><p>{ hiddenElementCounts.elementOne.Childcount }</p></div>
                                  <div><p onClick={(e) => handleIncrement("elementOne", "Childcount",e ) } > +</p></div>
                                </div>
                              </div>

                              {/* HiddenDiv3 */}
                              <div className="hiddenInfo_div" id="PawHiddinElmOne_border">
                                <div className="HiddenDiv1">
                                  <div className="hiddeninfo_heading_img"><img src="/assets/img/Widget/paw.png"alt="Paw" /></div>
                                  <div className="hiddeninfo_heading_tags" id="PawHiddinElmOne" >
                                    <h5>Pets</h5>
                                    <p></p>
                                  </div>
                                </div>
                                <div className="HiddenDiv2" onClick={(e) => e.stopPropagation()}>
                                  <div> <p onClick={(e) =>handleDecrement("elementOne","Petcount",e)}>-</p></div>
                                  <div> <p>{hiddenElementCounts.elementOne.Petcount}</p></div>
                                  <div><p onClick={(e) =>handleIncrement("elementOne","Petcount",e)}>+</p></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Passenger Elemnt (Elemnt One) End */}

                    {/* vechial (Elemnt Two) elemnet start */}
                    <div className={`elements_two ${ arrowRotationPass.elementTwo ? "arrowrotate" : "" }`} style={{ cursor: "pointer" }} >
                      <div className="dyanmicSelect_two">
                        <label className="labelsForPassanger_two"> Vehicles </label>
                        <div ref={vehicleTagRef} className="passenger_Tag_two" onClick={() => toggleContent("elementTwo")} >
                          <div className="passenger_Tag_two_Box1">
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/car.png" alt="Car" />
                              <p>{hiddenElementCounts.elementTwo.Carcount}</p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/Scooter2.0.png" alt="Bike" />
                              <p>{hiddenElementCounts.elementTwo.Scootercount}</p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/cycle.png" alt="Cycle" />
                              <p>{hiddenElementCounts.elementTwo.Cyclecount}</p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/Bus2.0.png" alt="Bus"/>
                              <p>{hiddenElementCounts.elementTwo.Buscount}</p>
                            </div>
                          </div>
                          <div className={`toggle-button-image_two ${ arrowRotationPass.elementTwo ? "rotate_90" : "" }`}>
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="DownArrow" />
                          </div>
                        </div>
                      </div>
                      {/* Hidden section for Element Two */}
                      {activeElement === "elementTwo" && (
                        <div className="elemt_two_hidden" onClick={(e) => e.stopPropagation()} >
                          <div className="additional_content_two" id="elementTwo_hidden"
                            style={{
                              top: direction === "down" ? "107%" : "auto",
                              bottom: direction === "up" ? "80%" : "auto",
                            }}>
                            <div className="Hidden_Info_two">
                              {/* HiddenDiv1 */}
                              <div className="hiddenInfo_div_two">
                                <div className="HiddenDiv1_two">
                                  <div className="hiddeninfo_heading_img_two">
                                    <img src="/assets/img/Widget/car.png" alt="1" />
                                  </div>
                                  <div className="hiddeninfo_heading_tags_two">
                                    <h5>Car</h5>
                                  </div>
                                </div>
                                <div className="HiddenDiv2_two" onClick={(e) => e.stopPropagation()} >
                                  <div><p onClick={(e) => handleDecrement( "elementTwo", "Carcount", e)}>-</p></div>
                                  <div><p>{hiddenElementCounts.elementTwo.Carcount}</p></div>
                                  <div><p onClick={(e) => handleIncrement( "elementTwo","Carcount",e)}>+</p></div>
                                </div>
                              </div>

                              {/* HiddenDiv2 */}
                              <div className="hiddenInfo_div_two">
                                <div className="HiddenDiv1_two">
                                  <div className="hiddeninfo_heading_img_two">
                                    <img src="/assets/img/Widget/Scooter2.0.png" alt="Bike"/>
                                  </div>
                                  <div className="hiddeninfo_heading_tags_two">
                                    <h5>Bike</h5>
                                    <p>*No e-scooters allowed</p>
                                  </div>
                                </div>
                                <div className="HiddenDiv2_two" onClick={(e) => e.stopPropagation()} >
                                  <div><p onClick={(e) => handleDecrement( "elementTwo", "Scootercount", e)}>-</p></div>
                                  <div><p>{hiddenElementCounts.elementTwo.Scootercount}</p></div>
                                  <div> <p onClick={(e) => handleIncrement( "elementTwo","Scootercount", e)}>+</p></div>
                                </div>
                              </div>

                              {/* HiddenDiv3 */}
                              <div className="hiddenInfo_div_two">
                                <div className="HiddenDiv1_two">
                                  <div className="hiddeninfo_heading_img_two">
                                    <img src="/assets/img/Widget/cycle.png" alt="Cycle" />
                                  </div>
                                  <div className="hiddeninfo_heading_tags_two">
                                    <h5>Cycle</h5>
                                  </div>
                                </div>
                                <div className="HiddenDiv2_two" onClick={(e) => e.stopPropagation()} >
                                  <div><p onClick={(e) => handleDecrement( "elementTwo", "Cyclecount",e )}>-</p></div>
                                  <div><p>{hiddenElementCounts.elementTwo.Cyclecount}</p></div>
                                  <div><p onClick={(e) => handleIncrement( "elementTwo", "Cyclecount", e ) } >+</p></div>
                                </div>
                              </div>

                              {/* HiddenDiv4 */}
                              <div className="hiddenInfo_div_two" id="hiddenElmTwo_Border" onClick={handleDivBusClick} >
                                <div className="HiddenDiv1_two">
                                  <div className="hiddeninfo_heading_img_two">
                                    <img src="/assets/img/Widget/Bus2.0.png" alt="Bus"/>
                                  </div>
                                  <div className="hiddeninfo_heading_tags_two">
                                    <h5>Bus</h5>
                                    <p>Contact To book</p>
                                  </div>
                                </div>
                                <div className="HiddenDiv2_two" >
                                  <div><p>-</p></div>
                                  <div><p>0</p></div>
                                  <div><p>+</p></div>
                                </div>
                              </div>
                              <Modal isOpen={isOpen}  contentLabel="Example Modal" className="custom-modal-alert"  >
                                    <p>For Bus Bookings, Please contact our booking helpline on 8291902662</p>
                                    <div className="ModalButton"><button onClick={closeModal}>Ok</button>
                                    </div>
                               </Modal>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* vechical Elemnt (Elemnt Two) End */}

                    {/* button start */}
                    <div className="abcbtn" onClick={() => SearchTripsHome()}>
                      <button type="button">Search</button>
                    </div>
                    <Modal isOpen={isBrowserAlerts}  contentLabel="Example Modal" className="custom-modal-alert">
                        <p>{isBrowserAlerts}</p>
                        <div className="ModalButton"><button onClick={closeModal}>Ok</button></div>
                    </Modal>  
                    {/* button end */}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* Mobile View */}
      <div className="Mobile_View_vegit">
        <div className="superAccordianMob">
          <div className="accordian_btn_mobile" onClick={toggleContents}>
            <div className="accordion_header_mobile">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.82" height="8.41" viewBox="0 0 16.82 8.41">
                <path id="Path" d="M19,15,12,9,5,15" transform="translate(-3.59 -8)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
              </svg>
            </div>
          </div>
          <div className="button_container" onClick={toggleContents} style={{backgroundColor: isAlertsOpen? "#d4f4ff" : "#FFFFFF99",}}>
            <img src="/assets/img/Widget/DangerCircle.png" alt="Circle" />
            <p className="SpeacialPtagClassAlert">{alerts[0]}</p>
            {/* <p className="SpeacialPtagClassAlert">Schedule is live till {moment(endsailingdate, ['MM/DD/YYYY']).format("Do MMM YYYY")}</p> */}
            {showContent && (
              <div className="overlay" onClick={closeContent} ref={overlayRef}>
                <div className="content_mob_scrren">
                  <div className="content_header">
                    <h1>Notifications</h1>
                    <button onClick={toggleContents}>            
                      <svg xmlns="http://www.w3.org/2000/svg" width="30.406" height="30.406" viewBox="0 0 30.406 30.406">
                        <g id="Add_Circle" data-name="Add Circle" transform="translate(-23.545 15.203) rotate(-45)">
                          <path id="Path_1888" data-name="Path 1888" d="M20.25,10.25a2,2,0,1,0-4,0v6h-6a2,2,0,1,0,0,4h6v6a2,2,0,1,0,4,0v-6h6a2,2,0,1,0,0-4h-6Z" transform="translate(9.149 9.149)" stroke="#fff" strokeWidth="1.5"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div className="content_body">
                    {alerts.map((alert, index) => (
                      <div className="maindiv" key={index}>
                        <img src="/assets/img/Widget/DangerCircle.png" alt="Circle" />                 
                        <p className="dark-background-text">{alert}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* tabs container  start */}

        <div className="tabs_container_mob">
          <div className="tabs_mob" >
            <div className="Widget_checkboxs_mob" onClick={() => handleTab2CheckboxChange()}>
              <input type="checkbox" checked={showonwardbtn}  className="round-checkbox" />
              <p>One Way</p>
            </div>
            <div className="Widget_checkboxs_mob" onClick={() => handleTab1CheckboxChange()}>
              <input style={{ cursor: "pointer" }} type="checkbox" checked={showroundbtn}  className="round-checkbox"/>
              <p>Round Trip</p>
            </div>
          </div>
          <div className="content_mob">
            {showTab1 && (
              <div className="Tab_RoundTip_mob">
                <div className="tab-content_mob">
                  <div className="Form_To_mob">
                    {/* form to tag mobils start */}
                    <div className="Form_To">
                      <div className="element_Form_To"style={{ cursor: "pointer" }}>
                        <label className="Form_to_labels">From</label>
                        <div className="custom_dropdown" onClick={openMobilePopup}>
                          <div className={`custom_dropdown__toggle ${arrowRotated[0] ? "rotate-arrow" : "" }`} onClick={toggleFromDropdown}>
                            <span className="slectTagNmaes">{selectedFromOption || selectedMobileOption}</span>
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="down-arrow" className="custom_dropdown__arrow"/>
                          </div>
                        </div>
                      </div>
                      <div className="For_To_Logo" style={{ cursor: "pointer" }} onClick={toggleFromTo}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.828" height="19.828" viewBox="0 0 14.828 19.828">
                          <g id="Sort_Horizontal" data-name="Sort Horizontal" transform="translate(-4.586 -1.586)">
                            <path id="Path_3" data-name="Path 3" d="M18,8H6M6,8l4.125-4M6,8l4.125,4" transform="translate(0 -1)" fill="none" stroke="#000" strokeLinecap="round"strokeLinejoin="round" strokeWidth="2"/>
                            <path id="Path_4" data-name="Path 4" d="M6,16H18m0,0-4.125-4M18,16l-4.125,4" fill="none" stroke="#000" strokeLinecap="round"strokeLinejoin="round" strokeWidth="2"/>
                          </g>
                        </svg>
                      </div>
                      <div className="element_Form_To" style={{ cursor: "pointer" }}>
                        <label className="Form_to_labels">To</label>
                        <div className="custom_dropdown" id="custom_dropdownTwo">
                          <div className={`custom_dropdown__toggle ${   arrowRotated[1] ? "rotate-arrow" : "" }`} onClick={toggleToDropdown}>
                            <span>{selectedToOption}</span>
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="down-arrow" className="custom_dropdown__arrow" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* form to tag mobils end */}
                    {/* date mobils start */}
                    <div className="Deaparture_Dates_mob">
                      <div className={`${showroundbtn ? "DepartureElemntOne_mob": "DepartureElemntOnefull" }`} style={{ cursor: "pointer" }}>
                        <label id="DepTag_mob1" className="dparture_date_lebels_mob">Departure</label>
                        <div className="DepartureDateImg_mob">
                          <div>
                            <img src="\assets\img\Widget/Date.png" alt="dateImage"/>
                          </div>
                          {/* <DatePicker
                            selected={selectedDateNew}
                            showPopperArrow={false}
                            onChange={handleStartDateChange}
                            minDate={selectedDateNew}
                            selectsStart
                            maxDate={new Date(endsailingdate)}
                            includeDates={availableDates}
                            customInput={
                              <p className="SuperDatesCalender_mob" style={{ cursor: "pointer" }}>
                                {selectedDateNew ? moment(selectedDateNew).format("DD MMM YYYY") : "Add Date"}
                              </p>
                            }
                          /> */}
                          <DatePicker
                                      selected={selectedDateNew}
                                      showPopperArrow={false}
                                      onChange={handleStartDateChange}
                                      minDate={new Date()}
                                      maxDate={new Date(endsailingdate)}
                                      includeDates={availableDates}
                                      customInput={
                                        <p className="SuperDatesCalender_mob" style={{ cursor: "pointer" }}>
                                          {selectedDateNew ? moment(selectedDateNew).format("DD MMM YYYY") : "Add Date"}
                                        </p>
                                      }
                          />
                        </div>
                      </div>
                      {/* return Start */}
                        <div className="RetutnElemntTwo_mob" style={{ cursor: "pointer", opacity: showroundbtn ? 1 : 1 }}>
                          <label id="DepTag_mob" className="dparture_date_lebels_mob" > Return </label>
                          <div  className={`DepartureDateImg_mob ${showroundbtn ? "" : ""}`}>
                            <div>
                              <img src="\assets\img\Widget/Date.png" alt="dateImage"/>
                            </div>
                            {/* <DatePicker
                              selected={selectedDateNew}
                              showPopperArrow={false}
                              onChange={handleEndDateChange}
                              minDate={new Date(selectedDateNew || new Date())}
                              maxDate={new Date(endsailingdate)}
                              startDate={selectedDateNew}
                              endDate={endDate}
                              //selectsRange
                              selectsEnd
                              includeDates={availableDates}
                              customInput={
                                <p className="SuperDatesCalenderRet" style={{ cursor: "pointer" }}>
                                  {endDate ? moment(endDate).format("DD MMM YYYY"): "Add Date"}
                                </p>
                                }
                            /> */}

                            
                          <DatePicker
                              selected={endDate}
                              showPopperArrow={false}
                              onChange={handleEndDateChange}
                              minDate={new Date(selectedDateNew || new Date())}
                              maxDate={new Date(endsailingdate)}
                              startDate={selectedDateNew}
                              endDate={endDate}
                              selectsEnd
                              includeDates={availableDates}
                              customInput={
                                <p className="SuperDatesCalenderRet" style={{ cursor: "pointer" }}>
                                  {endDate ? moment(endDate).format("DD MMM YYYY") : "Add Date"}
                                </p>
                              }
                            />

                          </div>
                        </div>
                      {/* )} */}
                    </div>
                    {/* Date tag mobils end */}
                    {/* passanger tag(elemnt One) mobils start */}
                    <div className="elements" style={{ cursor: "pointer" }} onClick={togglePopup}>
                      <div className="dyanmicSelect">
                        <label className="labelsForPassanger">Passengers</label>
                        <div className="passenger_Tag_mob">
                          <div className="passenger_Tag_Box1_mob">
                            <div className="imgeTag">
                              <img src="/assets/img/Widget/person.png" alt="Person" />
                              <p>{hiddenElementCounts.elementOne.Adultcount}</p>
                            </div>
                            <div className="imgeTag1">
                            <img src="/assets/img/Widget/child.png" alt="Child" />
                              <p>{hiddenElementCounts.elementOne.Childcount}</p>
                            </div>
                            <div className="imgeTag2">
                              <img src="/assets/img/Widget/paw.png" alt="Paw" />
                              <p>{hiddenElementCounts.elementOne.Petcount}</p>
                            </div>
                          </div>
                          <div className="passenger_Tag_Box2_mob">
                            <img className="toggleb_utton_image" src="/assets/img/Widget/Arrow_Down.png" alt="Down Circle" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Passanger tag(elemnt One) mobils end */}

                    {/* vechial (Elemnt Two) elemnet start */}
                    <div className="elements_two" style={{ cursor: "pointer" }} onClick={togglePopupTwo} >
                      <div className="dyanmicSelect_two">
                        <label className="labelsForPassanger_two">
                          Vehicles
                        </label>
                        <div className="passenger_Tag_two">
                          <div className="passenger_Tag_two_Box1">
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/car.png" alt="Car" />
                              <p>{hiddenElementCounts.elementTwo.Carcount}</p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/Scooter2.0.png" alt="Bike" />
                              <p>
                                {hiddenElementCounts.elementTwo.Scootercount}
                              </p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/cycle.png" alt="Cycle" />
                              <p>{hiddenElementCounts.elementTwo.Cyclecount}</p>
                            </div>
                            <div className="imgeTag_two">
                              <img src="/assets/img/Widget/Bus2.0.png" alt="Bus" />
                              <p>{hiddenElementCounts.elementTwo.Buscount}</p>
                            </div>
                          </div>
                          <div className="toggle-button-image_two">
                            <img src="/assets/img/Widget/Arrow_Down.png" alt="ArrowDown" onClick={togglePopupTwo}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* button start */}
                      <div className="abcbtn" style={{ cursor: "pointer" }} onClick={() => SearchTripsHome()}>
                        <button type="button">Search</button>
                      </div>
                  </div>
                </div>

                {/* tab content hidding section are start */}
                {/* form to tag  start */}
                <div className="mobilresponsive_popup">
                  {mobilePopupOpen && (
                    <div className="mobile_popup_FormTo" onClick={closeMobilePopup} >
                      <div className="content_mob_scrren_Form_To">
                        <div className="popup_contentForm_To">
                          <div className="close_button" onClick={closeMobilePopup} >
                            <p>Select Location</p>
                            <img src="assets/img/Widget/Arrow_Up.png" alt="Close" />
                          </div>
                          <div className="maindivForm_To" id="MumbiaUnderline">
                            <p onClick={() => handleFromChange("Mumbai")}>Mumbai </p>
                          </div>
                          <div className="maindivForm_To">
                            <p onClick={() => handleFromChange("Mandwa")}> Mandwa</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {/* form to tag  end */}

                {/* passangerr and vechical tag start */}
                <div className=" PassangerTag">
                  {popupVisible && (
                    <div className="PopUpContiner" onClick={(e) => e.stopPropagation()} >
                      <div className="modal-overlay" onClick={togglePopup}></div>
                      <div className="mobilePopup">
                        <div className="HeadingAndClos">
                          <h2>Add Passengers</h2>
                          <p onClick={togglePopup}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30.406" height="30.406" viewBox="0 0 30.406 30.406">
                              <g id="Add_Circle" data-name="Add Circle" transform="translate(-23.545 15.203) rotate(-45)">
                                <path id="Path_1888" data-name="Path 1888" d="M20.25,10.25a2,2,0,1,0-4,0v6h-6a2,2,0,1,0,0,4h6v6a2,2,0,1,0,4,0v-6h6a2,2,0,1,0,0-4h-6Z" transform="translate(9.149 9.149)" stroke="#fff" strokeWidth="1.5"/>
                              </g>
                            </svg>
                          </p>
                        </div>

                        <div className="Hidden_Info_mob">
                          <div className="hiddenInfo_div">
                            <div className="HiddenDiv1">
                              <div className="hiddeninfo_heading_img">
                              <img src="/assets/img/Widget/person.png" alt="1"/>
                              </div>
                              <div className="hiddeninfo_heading_tags">
                                <h5>Adult</h5>
                                <p>Over 3 Years</p>
                              </div>
                            </div>
                            <div className="HiddenDiv2_mob">
                              <div> <p onClick={(e) =>   handleDecrement("elementOne","Adultcount",e)}>- </p></div>
                              <div><p> {hiddenElementCounts.elementOne.Adultcount}</p></div>
                              <div><p onClick={(e) =>  handleIncrement("elementOne","Adultcount",e)}>+</p></div>
                            </div>
                          </div>
                          {/* HiddenDiv2 */}
                          <div className="hiddenInfo_div">
                            <div className="HiddenDiv1">
                              <div className="hiddeninfo_heading_img">
                              <img src="/assets/img/Widget/child.png" alt="1"/>
                              </div>
                              <div className="hiddeninfo_heading_tags">
                                <h5>Infants</h5>
                                <p>Below 3 Years</p>
                              </div>
                            </div>
                            <div className="HiddenDiv2_mob">
                              <div><p onClick={(e) =>   handleDecrement("elementOne","Childcount",e)}>-</p></div>
                              <div><p>{hiddenElementCounts.elementOne.Childcount}</p></div>
                              <div><p onClick={(e) =>   handleIncrement("elementOne","Childcount",e)}>+</p></div>
                            </div>
                          </div>
                          {/* HiddenDiv3 */}
                          <div className="hiddenInfo_div" id="PawHiddinElmOne_border">
                            <div className="HiddenDiv1">
                              <div className="hiddeninfo_heading_img" >
                                <img src="/assets/img/Widget/paw.png" alt="1" />
                              </div>
                              <div className="hiddeninfo_heading_tags" id="PawHiddinElmOne">
                                <h5>Pets</h5>
                                {/* <p>Over 3 Years</p> */}
                              </div>
                            </div>
                            <div className="HiddenDiv2_mob" style={{ cursor: "pointer" }}>
                              <div><p onClick={(e) =>   handleDecrement("elementOne", "Petcount", e) }> -</p></div>
                              <div><p>{hiddenElementCounts.elementOne.Petcount}</p></div>
                              <div><p onClick={(e) =>   handleIncrement("elementOne", "Petcount", e) }>+</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* passangerr and vechical tag start */}
                <div className="VechcalalTag">
                  {popupVisibleTwo && (
                    <div className="PopupContainer" onClick={(e) => e.stopPropagation()} >
                      <div className="modal-overlay" onClick={togglePopupTwo}></div>
                      <div className="mobilePopup">
                        <div className="HeadingAndClos">
                          <h2>Add Vehicles</h2>
                          <p onClick={togglePopupTwo}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="30.406" height="30.406" viewBox="0 0 30.406 30.406">
                            <g id="Add_Circle" data-name="Add Circle" transform="translate(-23.545 15.203) rotate(-45)">
                              <path id="Path_1888" data-name="Path 1888" d="M20.25,10.25a2,2,0,1,0-4,0v6h-6a2,2,0,1,0,0,4h6v6a2,2,0,1,0,4,0v-6h6a2,2,0,1,0,0-4h-6Z" transform="translate(9.149 9.149)" stroke="#fff" strokeWidth="1.5"/>
                            </g>
                          </svg>
                          </p>
                        </div>

                        <div className="Hidden_Info_two">
                          {/* HiddenDiv1 */}
                          <div className="hiddenInfo_div_two">
                            <div className="HiddenDiv1_two">
                              <div className="hiddeninfo_heading_img_two">
                                <img src="/assets/img/Widget/car.png" alt="Car" />
                              </div>
                              <div className="hiddeninfo_heading_tags_two">
                                <h5>Car</h5>
                              </div>
                            </div>
                            <div className="HiddenDiv2_two_mob" style={{ cursor: "pointer" }}>
                              <div> <p onClick={(e) =>handleDecrement("elementTwo", "Carcount", e) } > - </p> </div>
                              <div> <p>{hiddenElementCounts.elementTwo.Carcount}</p></div>
                              <div> <p onClick={(e) =>handleIncrement("elementTwo", "Carcount", e) } >+</p></div>
                            </div>
                          </div>

                          {/* HiddenDiv2 */}
                          <div className="hiddenInfo_div_two">
                            <div className="HiddenDiv1_two">
                              <div className="hiddeninfo_heading_img_two">
                                <img src="/assets/img/Widget/Scooter2.0.png" alt="Bike" />
                              </div>
                              <div className="hiddeninfo_heading_tags_two">
                                <h5>Bike</h5>
                                <p>*No e-scooters allowed</p>
                              </div>
                            </div>
                            <div className="HiddenDiv2_two_mob">
                              <div><p onClick={(e) =>handleDecrement("elementTwo","Scootercount",e) }>-</p></div>
                              <div><p> {hiddenElementCounts.elementTwo.Scootercount}</p></div>
                              <div><p onClick={(e) =>   handleIncrement("elementTwo","Scootercount",e) }>+</p></div>
                            </div>
                          </div>

                          {/* HiddenDiv3 */}
                          <div className="hiddenInfo_div_two">
                            <div className="HiddenDiv1_two">
                              <div className="hiddeninfo_heading_img_two">
                                <img src="/assets/img/Widget/cycle.png" alt="Cycle" />
                              </div>
                              <div className="hiddeninfo_heading_tags_two">
                                <h5>Cycle</h5>
                              </div>
                            </div>
                            <div className="HiddenDiv2_two_mob">
                              <div><p onClick={(e) =>   handleDecrement("elementTwo","Cyclecount",e) }> - </p></div>
                              <div><p> {hiddenElementCounts.elementTwo.Cyclecount}</p></div>
                              <div><p onClick={(e) =>   handleIncrement("elementTwo","Cyclecount",e) }>+</p></div>
                            </div>
                          </div>

                          {/* HiddenDiv4 */}
                          <div className="hiddenInfo_div_two" id="hiddenElmTwo_Border">
                            <div className="HiddenDiv1_two">
                              <div className="hiddeninfo_heading_img_two">
                                <img src="/assets/img/Widget/Bus2.0.png" alt="Bus" />
                              </div>
                              <div className="hiddeninfo_heading_tags_two">
                                <h5>Bus</h5>
                                <p>Contact To book</p>
                              </div>
                            </div>
                            <div className="HiddenDiv2_two_mob" onClick={handleDivBusClick}>
                              <div><p>-</p></div>
                              <div><p>{hiddenElementCounts.elementTwo.Buscount}</p></div>
                              <div><p>+</p></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewWidget;
