

import React from "react";
import { Route, Routes } from "react-router-dom"; 
import HomePage from "../Pages/Home/HomePage";
import DepartureM2M from "../Pages/Onword/DepartureM2M";
import ReturnM2M from "../Pages/Return/ReturnM2M";
import Calendar from "../Pages/Schedule/Schedule";
import Vehicle from "../Pages/Vehicle/Vehicle";
import AboutUS from "../Pages/About_Us/AboutUS";
import ConfirmationPage from "../Pages/Confirmation/ConfirmationPage";
import ThankYouPage from "../Pages/ThankYouPage/ThankYouPage";
import AccountPage from "../Pages/Account/AccountPage";
import SeatingArea from "../Pages/Seating_Area/SeatingArea";
import Signup from "../Pages/SignUp/Signup";
import Login from "../Pages/Login/Login";
import BookingHistory from "../Pages/BookingHistory/BookingHistory";
import Notification from "../Pages/NotificationPAge/Notification";
import ContactForm from "../Pages/ContactForm/ContactForm";
import FaqPage from "../Pages/FAQ/FaqPage";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import BookingStatus from "../Pages/BookingStatus/BookingStatus";
import Onward_M2M_Page from "../Pages/Onward_Experience_Pages/Onward_M2M_Page";
import EventsPage from "../Pages/Onward_Experience_Pages/EventsPage";
import Onboard_M2M_Princess from "../Pages/Onward_Experience_Pages/Onboard_M2M_Princess";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
import Travel_Guide from "../Pages/Travel_Guide/Travel_Guide";
import EventModalMobile from "../Pages/Schedule/EventModalMobile";
import ExplorePage from "../Pages/Explore/ExplorePage";
import Explore_SubTabsPage from "../Pages/Explore/Explore_SubTabsPage";
import Explore_SubTabsPage_Hotels from "../Pages/Explore/Explore_SubTabsPage_Hotels";
import Explore_SubTabsPage_Activities from "../Pages/Explore/Explore_SubTabsPage_Activities";
import Explore_SubTabsPage_Sightseeing from "../Pages/Explore/Explore_SubTabsPage_Sightseeing";
import PrivacyPolicy from "../Pages/TermsAndCondition/PrivacyPolicy";

const AllRoutes = () => {
  return (
    <div>
      <Routes> {/* Use Routes instead of Switch */}
        <Route path="/" element={<HomePage />} />
        <Route path="/explore" element={<ExplorePage />} />
        <Route path="/explore-subtab/restaurants" element={<Explore_SubTabsPage />} />
        <Route path="/explore-subtab/hotels" element={<Explore_SubTabsPage_Hotels />} />
        <Route path="/explore-subtab/activities" element={<Explore_SubTabsPage_Activities />} />
        <Route path="/explore-subtab/sightseeing" element={<Explore_SubTabsPage_Sightseeing />} />

        <Route exact path="/" element={<HomePage/>} />
        <Route path="/onward-departure" element={<DepartureM2M/>} />
        <Route path="/onward-return" element={<ReturnM2M/>} />
        <Route path="/vehicle" element={<Vehicle/>} />
        <Route path="/onward-seats-selection" element={<SeatingArea/>} />
        <Route path="/schedule" element={<Calendar/>} />
        <Route path="/mobile-schedule-event" element={<EventModalMobile/>} />
        <Route path="/about-us" element={<AboutUS/>} />
        <Route path="/confirmation" element={<ConfirmationPage/>} />
        <Route path="/confirm" element={<ThankYouPage/>} />
        <Route path="/contact" element={<ContactForm/>} />
        <Route path="/faq" element={<FaqPage/>} />
        <Route path="/personal-information" element={<AccountPage/>} />
        <Route path="/signup" element={<Signup/>} />

        <Route path="/login" element={<Login/>} />

        <Route path="/booking-history" element={<BookingHistory/>} />
        <Route path="/notifications" element={<Notification/>} />
        <Route path="/error-page" element={<ErrorPage/>} />
        <Route path="/booking-status" element={<BookingStatus/>} />
        <Route path="/m2m-1" element={<Onward_M2M_Page/>} />
        <Route path="/princess" element={<Onboard_M2M_Princess/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />

        <Route path="/events" element={<EventsPage/>} />
        <Route path="/terms-conditions" element={<TermsAndCondition/>} />
        <Route path="/travel-guide" element={<Travel_Guide/>} />

        <Route path="/explore" element={<ExplorePage/>} />
        <Route path="/explore/:category/:postName" element={<Explore_SubTabsPage/>} />
      </Routes>
    </div>
  );
};

export default AllRoutes;
