import React, { useEffect, useState } from "react";
import "./TermsAndCondition.css";
import FotterAll from "../../Components/Footer/FotterAll";
import MainNavbar from "../../Components/Navbar/MainNavbar";
import StaticDataService from "../../services/staticData";
import Meta from "../../Components/Meta";

const PrivacyPolicy = () => {
  const [seoMeta, setSeoMeta] = useState(null);

  //FOR META TAGS
  useEffect(() => {
    StaticDataService.SeoMetaServices("PrivacyPolicy")
      .then((response) => {
        if (response.status !== "error") {
          setSeoMeta(response.seoMeta[0]);
        } else {
          /* setError(response.message); */
        }
      })
      .catch((err) => {
        //setError(err.message);
      });
  }, []);

  const [TemsData, setTermsData] = useState();

  useEffect(() => {
    StaticDataService.StaticData()
      .then((response) => {
        console.log("Aksh :",response)
        setTermsData(response.data[4].description);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {seoMeta ? (
        <Meta
          title={seoMeta.title}
          description={seoMeta.description}
          keywords={seoMeta.keywords}
          site_name={seoMeta.site_name}
          canonicalUrl={seoMeta.canonicalUrl}
          url={seoMeta.url}
          image={seoMeta.image}
        />
      ) : null}
      <div className="navbarContainer-sticky Main-navbar-border">
        <MainNavbar />
      </div>
      <div className="TermsAndcondition_Content">
        <div className="TermsAndcondition_Content_main">
          <div
            className="TermsAndcondition_SubText"
            dangerouslySetInnerHTML={{
              __html: TemsData,
            }}
          />

          <div className="TermsAndConditionButton_div">
           
           
              <button className="TermsAndConditionButton">Back</button>
          </div>
        </div>
      </div>

      <FotterAll />
    </div>
  );
};

export default PrivacyPolicy;



// import React from 'react';
// import "./PrivacyPolicy.css";
// import MainNavbar from '../../Components/Navbar/MainNavbar';
// import FotterAll from '../../Components/Footer/FotterAll';


// const PrivacyPolicy = () => {
//   return (
//     <>
//         <div className="navbarContainer-sticky Main-navbar-border">
//             <MainNavbar isHomepage={false} />
//         </div>
//         <div className='Privacy-policy-container'>
//             <div className='Privacy-policy-content'>
//                 <h1>Privacy Policy of M2M Ferries Private Limited</h1>
//                 <p>
//                     M2M Ferries Private Limited operates the www.m2mferries.com website, which provides the SERVICE. <br />
//                     This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the M2M Ferries website. <br />
//                     If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. <br />
//                     The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at www.m2mferries.com, unless otherwise defined in this Privacy Policy. <br />
//                 </p>
//             </div>
//         </div>
//         <div>
//             <FotterAll />
//         </div>
//     </>
//   )
// }

// export default PrivacyPolicy